import { useLazyListObjectsQuery } from '@local/api-clients/src/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { useSelection } from '@local/webviz/dist/context/hooks/useSelection';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useCustomUpsertFileByPathMutation } from 'src/apiClients/file/customFileEndpoints';
import { waitForMs } from 'src/apiClients/file/utils';
import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    AGGREGATE_GEOMETRY_NAME,
    DEFAULT_TOLERANCE,
    GTM_FOLDER_PREFIX,
    MESH_SCHEMA,
} from 'src/constants';
import {
    GtmProject,
    GtmProjectInput,
    GtmAnalyticalModel,
    AggregatableObject,
} from 'src/gtmProject/Project.types';
import { useSceneObjectDataManager } from 'src/hooks';
import { useLazyInitAggregate } from 'src/hooks/aggregation/useLazyInitAggregate';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import { ERROR_CREATING_PROJECT, PROJECT_EXTENSION } from 'src/strings';
import { ObjectIdWithVersion, ObjectId, VersionId } from 'src/types/core.types';
import { boundingBoxToGtmBounds, rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import {
    BoundingBox,
    getBoundingBoxSnapshot,
    computeBoundingBoxFromCenter,
} from 'src/utils/xyzUtils';
import { CoordinatesInput } from 'src/visualization/Common/CoordinatesInput';
import { CoordinatesDisabledState } from 'src/visualization/Common/CoordinatesInput.types';
import { initialColorGenerator } from 'src/visualization/context/generateData';

import {
    BOUNDING_BOX_TITLE,
    NAME_TITLE,
    BOUNDING_BOX_DEFAULT_NAME,
    MAX_TITLE,
    MIN_TITLE,
    ACCEPT,
    CANCEL,
    ERROR_MAX_MUST_GREATER_THAN_MIN,
    ENCLOSE_ENTIRE_MODEL,
    NO_SELECTION_TEXT,
} from './BoundaryCreationDialog.constants';
import {
    BoundaryCreationDialogErrorState,
    BoundaryCreationDialogProps,
} from './BoundaryCreationDialog.types';
import { BoundaryCreationModal, BoundaryCreationModalState } from './BoundaryCreationModal';

const BOUNDARY_CREATION_VIEW_ID = 'boundary-creation';

const defaultErrorState: BoundaryCreationDialogErrorState = {
    minErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
    maxErrorState: {
        isErrorX: false,
        isErrorY: false,
        isErrorZ: false,
    },
};

function isBoundingBoxError(errorState: BoundaryCreationDialogErrorState): boolean {
    const { minErrorState, maxErrorState } = errorState;
    return (
        minErrorState.isErrorX ||
        minErrorState.isErrorY ||
        minErrorState.isErrorZ ||
        maxErrorState.isErrorX ||
        maxErrorState.isErrorY ||
        maxErrorState.isErrorZ
    );
}

function detectBoundingBoxError(boundingBox: BoundingBox): BoundaryCreationDialogErrorState {
    const { xMin, yMin, zMin, xMax, yMax, zMax } = boundingBox;

    const minErrorState = {
        isErrorX: !Number.isFinite(xMin) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMin) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMin) || zMax <= zMin,
    };
    const maxErrorState = {
        isErrorX: !Number.isFinite(xMax) || xMax <= xMin,
        isErrorY: !Number.isFinite(yMax) || yMax <= yMin,
        isErrorZ: !Number.isFinite(zMax) || zMax <= zMin,
    };
    return {
        minErrorState,
        maxErrorState,
    };
}

export function BoundaryCreationDialog({
    existingProjectNames,
    onClose,
    onBoundaryCreated,
}: Readonly<BoundaryCreationDialogProps>) {
    const sceneObjects = useAppSelector(sceneObjectMap);
    const params = useParams();
    const { getState, setXyzStateDirectly, removeViewsFromPlotDirectly, addViewToPlotDirectly } =
        useBaseXyz();
    const { selectionState, unselect } = useSelection();
    const gooseContext = useGooseContext();
    const organisationId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [updateFile] = useCustomUpsertFileByPathMutation();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();
    const [InitAggregateTrigger] = useLazyInitAggregate();
    const [GooseListObjectsTrigger] = useLazyListObjectsQuery();

    const [boundaryName, setBoundaryName] = useState<string>(BOUNDING_BOX_DEFAULT_NAME);
    const { getPlotObjectsBoundingBox } = useSceneObjectDataManager();
    const fullyEnclosingExtents = getPlotObjectsBoundingBox();
    const [boundaryExtents, setBoundaryExtents] = useState<BoundingBox | undefined>(
        fullyEnclosingExtents,
    );
    const [isEntireDomainBounded, setIsEntireDomainBounded] = useState<boolean>(false);
    const [modalState, setModalState] = useState<BoundaryCreationModalState>(
        BoundaryCreationModalState.STARTED,
    );
    const [isClipping, setIsClipping] = useState<boolean>(false);

    const [errorState, setErrorState] =
        useState<BoundaryCreationDialogErrorState>(defaultErrorState);

    const { addMessage } = useMessagesContext();

    const updateboundaryExtentsIfItExists = useCallback(
        (updatedValue: Partial<BoundingBox>) => {
            if (boundaryExtents && updatedValue) {
                const updatedBoundary = { ...boundaryExtents, ...updatedValue };
                const newErrorState = detectBoundingBoxError(updatedBoundary);
                setErrorState(newErrorState);
                setBoundaryExtents((currentBoundary) => ({
                    ...currentBoundary!,
                    ...updatedValue,
                }));
            }
        },
        [boundaryExtents],
    );

    function discardCurrentBoundaryExtents() {
        removeViewsFromPlotDirectly([BOUNDARY_CREATION_VIEW_ID]);
        setBoundaryExtents(undefined);
    }

    function closeDialog() {
        discardCurrentBoundaryExtents();
        onClose();
    }

    async function uploadNewProject(project: GtmProject) {
        const fileName = `${project.name}.${PROJECT_EXTENSION}`;
        return updateFile({
            workspaceId,
            organisationId,
            filePath: fileName,
            uploadFile: new File(
                [new Blob([JSON.stringify(project)], { type: 'application/json' })],
                fileName,
            ),
        });
    }

    async function handleError(error: unknown, projectName: string) {
        addMessage({
            message: ERROR_CREATING_PROJECT,
            type: NotificationType.ERROR,
        });
        trackError(`Error: ${error} creating project "${projectName}"`);
        return Promise.reject(error);
    }

    async function makeOutputObjectsFromCreated(
        objects: ObjectIdWithVersion[],
    ): Promise<AggregatableObject[]> {
        // We need the objects' name which we don't have, so we have to list to get it :(
        // We filter to the name of the boundary so the response size is smaller.
        const { data: objectListing, isError } = await GooseListObjectsTrigger({
            orgId: organisationId,
            workspaceId,
            objectName: [`ilike:${GTM_FOLDER_PREFIX}/${boundaryName}*`],
        });

        if (isError || !objectListing) {
            return Promise.reject(new Error('Error listing objects for metadata generation'));
        }

        try {
            const outputObjects = objects.map((createdObject: ObjectIdWithVersion) => {
                const listedGooseObject = objectListing.objects.find(
                    (gooseObject) => createdObject.id === gooseObject.object_id,
                );
                if (!listedGooseObject) {
                    throw new Error(
                        `Error: created object with id '${createdObject.id}' not found in goose listing`,
                    );
                }

                return {
                    ...createdObject,
                    name: listedGooseObject.name,
                    schema: listedGooseObject.schema,
                    color: rgbArrayToGtmColor(initialColorGenerator()),
                    isAggregated: false,
                };
            });
            return outputObjects;
        } catch (error) {
            return await handleError(error, boundaryName);
        }
    }

    function createNewProject(
        projectName: string,
        inputObjects: GtmProjectInput[],
        objects: AggregatableObject[],
        boundaryId: string,
    ) {
        const project: GtmProject = {
            name: projectName,
            inputs: inputObjects,
            models: [
                {
                    bounds: boundingBoxToGtmBounds(boundaryExtents!, boundaryId),
                    objects,
                },
            ],
            history: [],
        };

        return project;
    }

    async function createAnalyticalBoundary(
        inputObjects: ObjectIdWithVersion[],
    ): Promise<{ projectObjects: ObjectIdWithVersion[]; boundaryId: string }> {
        if (!gooseContext) {
            // Shouldn't actually happen since we have an org route guard.
            trackError('Error: No goose context');
            throw new Error('No goose context');
        }

        const boundaryId = uuidv4();

        const creationParams = {
            ...boundingBoxToGtmBounds(boundaryExtents!, boundaryId),
            // In the future we should have a boundary name and a project name.
            // but at the moment we create a project with the boundary name.
            projectName: boundaryName,
            tolerance: DEFAULT_TOLERANCE,
        };

        const clipToBoundaryPromises = inputObjects.map((versionedObject) => {
            const body = formGtmMeshTransformationBody(
                gooseContext,
                GtmMeshTransformationAction.CreateAnalyticalBoundary,
                [versionedObject],
                creationParams,
            );
            return GtmMeshTransformationTrigger(body);
        });

        const clipToBoundaryResults = await Promise.all(clipToBoundaryPromises);

        if (clipToBoundaryResults.some(({ isError }) => isError)) {
            // Probably shouldn't error out completely.
            // Could report which surface failed.
            return Promise.reject(new Error('Error creating the analytical boundary.'));
        }

        const createdObjects = clipToBoundaryResults.map(({ data }) => data?.created || []).flat();

        if (createdObjects.length) {
            return {
                projectObjects: [...createdObjects],
                boundaryId,
            };
        }

        return Promise.reject(
            new Error(
                'Analytical boundary creation succeeded but no objects are within the bounds.',
            ),
        );
    }

    async function InitAggregate(boundaryId: string): Promise<ObjectIdWithVersion> {
        try {
            const { initResult: result, initIsError: isError } = await InitAggregateTrigger(
                boundingBoxToGtmBounds(boundaryExtents!, boundaryId),
            );

            if (isError) {
                throw new Error('Error making aggregate geometry');
            }

            if (result?.created.length) {
                return { ...result.created[0] };
            }

            throw new Error('No object created');
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function updateAggregateGeom(project: GtmProject) {
        const updatedProject = project;

        const { id: aggregateId, version: aggregateVersion } = await InitAggregate(
            (updatedProject.models[0] as GtmAnalyticalModel).bounds.boundaryId,
        );

        // This is a kludge to get a default color for us devs.
        const initialColor = initialColorGenerator();

        (updatedProject.models[0] as GtmAnalyticalModel).aggregateGeometry = {
            name: AGGREGATE_GEOMETRY_NAME,
            id: aggregateId,
            version: aggregateVersion,
            schema: MESH_SCHEMA,
            color: rgbArrayToGtmColor(initialColor),
        };

        return updatedProject;
    }

    async function handleCreateBoundary() {
        // TODO: GEOM-108 - Validate the bounds
        const inputObjects: ObjectIdWithVersion[] = Object.entries(sceneObjects)
            .filter(([objectId, _]) => getState().plot.views.includes(objectId))
            .map(([objectId, { versionId }]) => ({
                id: objectId as ObjectId,
                version: versionId as VersionId,
            }));

        let isError = false;
        try {
            setIsClipping(true);

            setModalState(BoundaryCreationModalState.STARTED);

            const { projectObjects: objectsInBoundary, boundaryId } =
                await createAnalyticalBoundary(inputObjects);

            setModalState(BoundaryCreationModalState.ANALYTICAL_BOUNDARY_CREATED);

            if (objectsInBoundary) {
                const projectObjects = await makeOutputObjectsFromCreated(objectsInBoundary);
                let project = createNewProject(
                    boundaryName,
                    inputObjects as GtmProjectInput[], // This is a bit of a hack for now - EH will clean this up upon further refactoring.
                    projectObjects,
                    boundaryId,
                );

                project = await updateAggregateGeom(project);

                setModalState(BoundaryCreationModalState.AGGREGATE_GEOMETRY_INITIALIZED);

                const uploadResponse = await uploadNewProject(project);

                setModalState(BoundaryCreationModalState.PROJECT_UPLOADED);

                if (!uploadResponse.error) {
                    onBoundaryCreated(uploadResponse.data);
                    setModalState(BoundaryCreationModalState.SUCCESS);
                } else {
                    isError = true;
                    setModalState(BoundaryCreationModalState.ERROR);
                }
            }
        } catch (error) {
            handleError(error, boundaryName);
            isError = true;
            setModalState(BoundaryCreationModalState.ERROR);
        }

        if (!isError) {
            // Briefly display the completed message before continuing.
            await waitForMs(1500);
            setIsClipping(false);
            closeDialog();
        }
        // else the error message will be displayed with a close/retry.
    }

    function adjustForUpdatedSelection() {
        if (selectionState?.position) {
            discardCurrentBoundaryExtents();
            setBoundaryExtents(
                computeBoundingBoxFromCenter(
                    selectionState.position,
                    getState().camera.radius / 10,
                ),
            );
        } else {
            unselect();
        }
    }

    useEffect(() => {
        if (!isEntireDomainBounded) {
            adjustForUpdatedSelection();
        }
    }, [selectionState]);

    useEffect(() => {
        if (isEntireDomainBounded) {
            setBoundaryExtents(fullyEnclosingExtents);
        }
    }, [fullyEnclosingExtents]);

    useEffect(() => {
        if (boundaryExtents) {
            setXyzStateDirectly(getBoundingBoxSnapshot(boundaryExtents, BOUNDARY_CREATION_VIEW_ID));
            addViewToPlotDirectly(BOUNDARY_CREATION_VIEW_ID);
        }
    }, [boundaryExtents]);

    const projectNameExists = existingProjectNames.includes(boundaryName.toLowerCase());
    const isBoundingError = isBoundingBoxError(errorState);

    return (
        <>
            {!boundaryExtents && (
                <>
                    <DialogHeader closeDialog={closeDialog} />
                    <Typography variant="body2" align="center">
                        {NO_SELECTION_TEXT}
                    </Typography>
                </>
            )}

            {isBoundingError && boundaryExtents && (
                <Alert
                    severity="error"
                    sx={{
                        marginLeft: (theme) => theme.spacing(2),
                        marginRight: (theme) => theme.spacing(2),
                        marginBottom: (theme) => theme.spacing(2),
                        marginTop: (theme) => theme.spacing(1),
                    }}
                >
                    {ERROR_MAX_MUST_GREATER_THAN_MIN}
                </Alert>
            )}

            {boundaryExtents && (
                <>
                    <BoundaryNameSection
                        isCurrentTextValid={!projectNameExists}
                        onChange={(event) => setBoundaryName(event.target.value)}
                    />
                    <EncloseModelCheckbox
                        onChange={updateboundaryExtentsIfItExists}
                        isEntireDomainBounded={isEntireDomainBounded}
                        setIsEntireDomainBounded={setIsEntireDomainBounded}
                    />
                    <MinMaxInputSection
                        boundingBox={boundaryExtents}
                        onChange={updateboundaryExtentsIfItExists}
                        boundingErrorState={errorState}
                        isEntireDomainBounded={isEntireDomainBounded}
                    />
                    <ButtonsSection
                        isCreateDisabled={projectNameExists || isBoundingError}
                        onCreateBoundary={handleCreateBoundary}
                        onDeleteBoundary={closeDialog}
                    />
                </>
            )}
            <BoundaryCreationModal
                isOpen={isClipping}
                state={modalState}
                onClose={() => setIsClipping(false)}
                onRetry={handleCreateBoundary}
            />
        </>
    );
}

function DialogHeader({ closeDialog }: { closeDialog: () => void }) {
    return (
        <ListItem>
            <ListItemText secondary={BOUNDING_BOX_TITLE} sx={{ textTransform: 'uppercase' }} />
            <IconButton onClick={closeDialog}>
                <CloseIcon />
            </IconButton>
        </ListItem>
    );
}

function OutlinedTextFieldSizedTextProps(
    themeSpacing: number,
): Pick<TextFieldProps, 'InputProps' | 'InputLabelProps'> {
    return {
        InputProps: {
            sx: {
                fontSize: (theme) => theme.spacing(themeSpacing),
            },
        },
        InputLabelProps: {
            sx: { fontSize: (theme) => theme.spacing(themeSpacing) },
        },
    };
}

function BoundaryNameSection({
    isCurrentTextValid,
    onChange,
}: {
    isCurrentTextValid: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <TextField
            size="small"
            label={NAME_TITLE}
            variant="outlined"
            onChange={onChange}
            defaultValue={BOUNDING_BOX_DEFAULT_NAME}
            error={!isCurrentTextValid}
            helperText={isCurrentTextValid ? undefined : 'The boundary name already exists'}
            sx={{
                marginLeft: (theme) => theme.spacing(2),
                marginRight: (theme) => theme.spacing(2),
                marginTop: (theme) => theme.spacing(2),
            }}
            {...OutlinedTextFieldSizedTextProps(1.75)}
        />
    );
}

interface MinMaxInputSectionProps {
    boundingBox: BoundingBox;
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    boundingErrorState: BoundaryCreationDialogErrorState;
    isEntireDomainBounded: boolean;
}

function MinMaxInputSection({
    boundingBox,
    onChange,
    boundingErrorState,
    isEntireDomainBounded,
}: Readonly<MinMaxInputSectionProps>) {
    const disabledState: CoordinatesDisabledState = {
        isXDisabled: isEntireDomainBounded,
        isYDisabled: isEntireDomainBounded,
        isZDisabled: isEntireDomainBounded,
    };

    return (
        <>
            <CoordinatesInput
                label={MIN_TITLE}
                coordinateValues={{ x: boundingBox.xMin, y: boundingBox.yMin, z: boundingBox.zMin }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Min`]: updatedValue })}
                errorState={boundingErrorState.minErrorState}
                disabledState={disabledState}
            />
            <CoordinatesInput
                label={MAX_TITLE}
                coordinateValues={{ x: boundingBox.xMax, y: boundingBox.yMax, z: boundingBox.zMax }}
                onChange={(axis, updatedValue) => onChange({ [`${axis}Max`]: updatedValue })}
                errorState={boundingErrorState.maxErrorState}
                disabledState={disabledState}
            />
        </>
    );
}

function ButtonsSection({
    isCreateDisabled,
    onCreateBoundary,
    onDeleteBoundary,
}: {
    isCreateDisabled: boolean;
    onCreateBoundary: () => Promise<void>;
    onDeleteBoundary: () => void;
}) {
    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                paddingTop: (theme) => theme.spacing(1.5),
                paddingBottom: (theme) => theme.spacing(1.5),
                paddingLeft: (theme) => theme.spacing(2),
                paddingRight: (theme) => theme.spacing(2),
            }}
        >
            <Button
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                onClick={onDeleteBoundary}
            >
                {CANCEL}
            </Button>
            <Button
                size="small"
                color="primary"
                variant="contained"
                fullWidth
                onClick={onCreateBoundary}
                disabled={isCreateDisabled}
            >
                {ACCEPT}
            </Button>
        </Stack>
    );
}

interface EncloseModelCheckboxProps {
    onChange: (updatedValue: Partial<BoundingBox>) => void;
    isEntireDomainBounded: boolean;
    setIsEntireDomainBounded: (value: boolean) => void;
}

function EncloseModelCheckbox({
    onChange,
    isEntireDomainBounded,
    setIsEntireDomainBounded: setEntireDomainBounded,
}: Readonly<EncloseModelCheckboxProps>) {
    const { getPlotObjectsBoundingBox } = useSceneObjectDataManager();
    const fullyEnclosingExtents = getPlotObjectsBoundingBox();
    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntireDomainBounded(event.target.checked);
        if (event.target.checked) {
            if (fullyEnclosingExtents) {
                onChange(fullyEnclosingExtents);
            }
        }
    };

    const checkboxControl = (
        <Checkbox
            color="primary"
            size="medium"
            onChange={onCheckboxChange}
            checked={isEntireDomainBounded}
        />
    );

    return (
        <FormControlLabel
            control={checkboxControl}
            labelPlacement="end"
            label={ENCLOSE_ENTIRE_MODEL}
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
        />
    );
}
