import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store/store';
import {
    ObjectsTabSelection,
    ProjectPanelState,
} from 'src/store/ui/projectPanel/projectPanel.types';

export const initialProjectPanelState: ProjectPanelState = {
    objectsPanelTabSelection: ObjectsTabSelection.ModelObjects,
    isSearchingWorkspace: false,
    workspaceSearchTerm: '',
    selectedWorkspaceObjectIds: [],
    shouldMinimizeProjectPanel: false,
    shouldOpenNoProjectDialog: false,
    shouldShowProjectPanel: true,
};

type SelectorTypeString = (state: RootState) => string;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeObjectsTabSelection = (state: RootState) => ObjectsTabSelection;
type SelectorTypeStringArray = (state: RootState) => string[];

const projectPanelState = (state: RootState): ProjectPanelState => state.projectPanel;
export const selectObjectsPanelTabSelection: SelectorTypeObjectsTabSelection = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.objectsPanelTabSelection,
);
export const selectIsSearchingWorkspaceObjects: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.isSearchingWorkspace,
);
export const selectWorkspaceSearchTerm: SelectorTypeString = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.workspaceSearchTerm,
);
export const selectSelectedWorkspaceObjectIds: SelectorTypeStringArray = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.selectedWorkspaceObjectIds,
);
export const selectShouldMinimizeProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldMinimizeProjectPanel,
);
export const selectShouldOpenNoProjectDialog: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldOpenNoProjectDialog,
);
export const selectShouldShowProjectPanel: SelectorTypeBoolean = createSelector(
    projectPanelState,
    (projectPanelStateRoot) => projectPanelStateRoot.shouldShowProjectPanel,
);
