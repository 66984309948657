import { ObjectIdWithVersion, GeoscienceObject } from 'src/types/core.types';
import { rgbArrayToGtmColor } from 'src/utils/typeTransformations';
import { initialColorGenerator } from 'src/visualization/context/generateData';

export function decorateNewObject(
    newObject: ObjectIdWithVersion,
    name: string,
    schema: string,
): GeoscienceObject {
    return {
        ...newObject,
        name,
        schema,
        color: rgbArrayToGtmColor(initialColorGenerator()),
    };
}
