export const REMOVE_DEGENERATE_TRIANGLES = 'Remove Degenerate Triangles';
export const NEEDLE_THRESHOLD_RATIO = 'Needle Threshold Ratio';
export const NEEDLE_THRESHOLD_ONE_TO = '1:';
export const NEEDLE_THRESHOLD_RATIO_INFO =
    'Threshold ratio of the shortest edge of a triangle to the longest edge at which a triangle is considered a needle. Needles are considered to be degenerate.';
export const NEEDLE_COLLAPSE_LENGTH = 'Needle Collapse Length';
export const NEEDLE_COLLAPSE_LENGTH_INFO =
    'Maximum edge length that will be collapsed via this operation. If value is zero, this parameter has no effect.';
export const CAP_MINIMUM_ANGLE = 'Cap Minimum Angle';
export const CAP_MINIMUM_ANGLE_INFO =
    'Threshold of a minimum angle, in degrees, such that if a triangle has an angle greater than this value, it is considered a cap. Caps are considered to be degenerate. The threshold is angle between `90` and `180` degrees.';
export const REMOVE = 'Remove';
export const CANCEL = 'Cancel';
export const REMOVING_DEGENERACIES = 'Removing Degeneracies...';
