export const FILL_HOLES = 'Fill Holes';
export const FILL_MODE = 'Fill Mode';
export const FILL_MODE_INFO =
    'If fairing is enabled, will try to make the fill C1 continuous with the hole boundary.';
export const SPECIFY_HOLE = 'Specify Hole';
export const SPECIFY_HOLE_INFO =
    'Optional: Identifier of the hole to fill. If not specified, all holes will be filled.';
export const FILL_REFINE_AND_FAIR = 'Fill, Refine, and Fair';
export const FILL_AND_REFINE = 'Fill and Refine';
export const FILL = 'Fill';
export const CANCEL = 'Cancel';
export const FILLING_HOLES = 'Filling Holes...';
