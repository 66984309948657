import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import { ArrowDownIcon } from '@local/web-design-system/dist/icons/Arrows';
import { Nullable } from '@local/webviz/dist/types/xyz';
import ExpansionPanel from '@mui/material/Accordion';
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useSceneObjectSelectionManager } from 'src/hooks/useSceneObjectSelectionManager';
import {
    selectCurrentProjectAnalyticalModel,
    selectCurrentShowVolumes,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';

import { ObjectListItemControl } from '../ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './AggregateTreePanel.styles';

export function SkeletonArtifactsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

function nonNullable<T>(value: T): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function AggregateTreePanel() {
    const { classes } = useStyles();
    const [currentVolumes, setCurrentVolumes] =
        useState<Nullable<{ volumes: GtmEvoOutputObject[] }>>(null);
    const [currentSelectableObjectList, setCurrentSelectableObjectList] = useState<
        GtmEvoOutputObject[]
    >([]);
    const [currentAggregate, setCurrentAggregate] =
        useState<Nullable<{ aggregate: GtmEvoOutputObject }>>(null);

    const { onObjectControlSelection } = useSceneObjectSelectionManager();
    const selectedObjectIds = useAppSelector(selectedSceneObjectIds);
    const currentAnalyticalModel = useAppSelector(selectCurrentProjectAnalyticalModel);
    const currentShowVolumes = useAppSelector(selectCurrentShowVolumes);
    const [expanded, setExpanded] = useState(false);
    const onChange = useCallback(() => setExpanded(!expanded), [expanded]);

    useEffect(() => {
        if (currentAnalyticalModel) {
            const { volumes, aggregateGeometry } = currentAnalyticalModel;
            const concatenatedModels = volumes
                ? [...volumes, aggregateGeometry].filter(nonNullable)
                : [];
            setCurrentVolumes(volumes ? { volumes } : null);
            setCurrentAggregate(aggregateGeometry ? { aggregate: aggregateGeometry } : null);
            setCurrentSelectableObjectList(concatenatedModels);
        } else {
            setCurrentVolumes(null);
            setCurrentAggregate(null);
            setCurrentSelectableObjectList([]);
        }
    }, [currentAnalyticalModel]);

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            <ExpansionPanel
                square
                TransitionProps={{ mountOnEnter: true, timeout: 20 }}
                expanded={expanded}
                onChange={onChange}
                classes={{
                    root: classes.expansionPanelRoot,
                    expanded: classes.expansionPanelExpanded,
                }}
            >
                <ExpansionPanelSummary
                    expandIcon={<ArrowDownIcon fontSize="inherit" />}
                    classes={{
                        root: classes.expansionPanelSummaryRoot,
                        expanded: classes.expansionPanelSummaryExpanded,
                        expandIconWrapper: classes.expansionPanelSummaryExpandIcon,
                        content: classes.expansionPanelSummaryContent,
                    }}
                >
                    {currentAggregate?.aggregate && currentSelectableObjectList && (
                        <Grid
                            key={currentAggregate.aggregate.id}
                            onClick={(event) =>
                                onObjectControlSelection(
                                    currentSelectableObjectList,
                                    0,
                                    currentAggregate.aggregate.id,
                                    event,
                                )
                            }
                        >
                            <ObjectListItemControl
                                objectListItem={currentAggregate.aggregate}
                                selectedObjectIds={selectedObjectIds}
                                addToSceneOnMount
                            />
                        </Grid>
                    )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetailsRoot }}>
                    {currentVolumes?.volumes &&
                        currentShowVolumes &&
                        currentSelectableObjectList &&
                        currentVolumes.volumes.map((child: GtmEvoOutputObject, index: number) => (
                            <Grid
                                key={child.id}
                                onClick={(event) =>
                                    onObjectControlSelection(
                                        currentSelectableObjectList,
                                        index,
                                        child.id,
                                        event,
                                    )
                                }
                            >
                                <ObjectListItemControl
                                    objectListItem={child}
                                    selectedObjectIds={selectedObjectIds}
                                />
                            </Grid>
                        ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
    );
}
