import { SplitLayout } from '@local/split-layout/dist/SplitLayout';
import {
    XyzContext,
    XyzInstanceContextValue,
    createXyzInstanceContext,
} from '@local/webviz/dist/context/createXyzInstanceContext';
import { XyzInstance } from '@local/webviz/dist/types';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UseNewUIContext } from 'src/components/contentScaffolding/ContentScaffolding';
import { UrlListener } from 'src/components/UrlListener/UrlListener';
import { CrossSectionPanel } from 'src/visualization/CrossSectionPanel/CrossSectionPanel';
import { ProjectPanel } from 'src/visualization/ProjectPanel/ProjectPanel';
import { SettingsPanel } from 'src/visualization/SettingsPanel/SettingsPanel';

import { Plot } from '../Plot/Plot';
import { ArtifactsPanel } from './ArtifactsPanel/ArtifactsPanel';

function ContentArea(
    xyzInstanceInitialized: boolean,
    setXyzInstanceContextValue: React.Dispatch<
        React.SetStateAction<XyzInstanceContextValue | null>
    >,
) {
    const { workspaceUuid } = useParams();
    const shouldUseNewUI = useContext(UseNewUIContext);

    if (shouldUseNewUI) {
        return (
            <>
                {xyzInstanceInitialized && workspaceUuid && (
                    <>
                        <ProjectPanel
                            sx={{ position: 'absolute', zIndex: 1, left: '16px', top: '16px' }}
                        />
                        <SettingsPanel />
                        <CrossSectionPanel />
                    </>
                )}
                <Plot
                    initialized={xyzInstanceInitialized}
                    onInitialized={(xyzInstance: XyzInstance) =>
                        setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
                    }
                />
            </>
        );
    }

    return (
        <SplitLayout
            leftPanelComponent={
                <Grid item xs zIndex={1}>
                    {xyzInstanceInitialized && workspaceUuid && <ArtifactsPanel />}
                </Grid>
            }
            rightPanelComponent={
                <Plot
                    initialized={xyzInstanceInitialized}
                    onInitialized={(xyzInstance: XyzInstance) =>
                        setXyzInstanceContextValue(createXyzInstanceContext(xyzInstance))
                    }
                />
            }
        />
    );
}

export function Visualization() {
    const [xyzInstanceContextValue, setXyzInstanceContextValue] =
        useState<XyzInstanceContextValue | null>(null);
    const xyzInstanceInitialized = Boolean(xyzInstanceContextValue);
    const shouldUseNewUI = useContext(UseNewUIContext);

    return (
        <>
            {/* EH: I want to bubble the URL listener up, but there are some details of react router to work out to do so. */}
            {!shouldUseNewUI && <UrlListener />}
            <XyzContext.Provider value={xyzInstanceContextValue}>
                {ContentArea(xyzInstanceInitialized, setXyzInstanceContextValue)}
            </XyzContext.Provider>
        </>
    );
}
