import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { NumericInput } from '@local/web-design-system/dist/components/NumericInput';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    DEFAULT_NEEDLETHRESHOLDRATIO,
    DEFAULT_CAPMINANGLEDEGREES,
    DEFAULT_NEEDLECOLLAPSELENGTH,
} from 'src/constants';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { ObjectId, VersionId } from 'src/types/core.types';
import { DeprecatedTransformationProgressModal } from 'src/visualization/TransformationProgressModal/DeprecatedTransformationProgressModal';

import { ParameterRow } from './ParameterRow/ParameterRow';
import {
    REMOVE_DEGENERATE_TRIANGLES,
    REMOVE,
    CANCEL,
    NEEDLE_THRESHOLD_RATIO,
    NEEDLE_THRESHOLD_ONE_TO,
    NEEDLE_THRESHOLD_RATIO_INFO,
    NEEDLE_COLLAPSE_LENGTH,
    CAP_MINIMUM_ANGLE,
    CAP_MINIMUM_ANGLE_INFO,
    NEEDLE_COLLAPSE_LENGTH_INFO,
    REMOVING_DEGENERACIES,
} from './RemoveDegenerateTriangles.constants';
import { useStyles } from './RemoveDegenerateTriangles.styles';

const NumericInputParameterRow = ({
    PrefixElements,
    value,
    onChange,
    tooltipText,
    min,
    exclusiveMin,
    max,
    exclusiveMax,
}: {
    PrefixElements: any;
    value: number;
    onChange: (value: number) => void;
    tooltipText: string;
    min?: number;
    exclusiveMin?: boolean;
    max?: number;
    exclusiveMax?: boolean;
}) => {
    const { classes } = useStyles();
    return (
        <ParameterRow
            ParameterElements={() => (
                <>
                    <PrefixElements />
                    <NumericInput
                        value={value}
                        onChange={(newValue) => onChange(newValue)}
                        min={min}
                        exclusiveMin={exclusiveMin}
                        max={max}
                        exclusiveMax={exclusiveMax}
                        arrows
                        className={classes.numericInput}
                    />
                </>
            )}
            tooltipText={tooltipText}
        />
    );
};

export const RemoveDegenerateTrianglesDialog = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) => {
    const { classes } = useStyles();
    const selectedObject = Object.values(useAppSelector(selectedSceneObjects))[0];
    const { executeTransformation, transformationStatus } = useTransformationManager();

    const [needleThresholdRatio, setNeedleThresholdRatio] = useState(DEFAULT_NEEDLETHRESHOLDRATIO);
    const [needleCollapseLength, setNeedleCollapseLength] = useState(DEFAULT_NEEDLECOLLAPSELENGTH);
    const [capMinAngleDegrees, setCapMinAngleDegrees] = useState(DEFAULT_CAPMINANGLEDEGREES);

    async function action(accept: boolean | null) {
        if (accept) {
            await executeTransformation(
                GtmMeshTransformationAction.RemoveDegenerateTris,
                [
                    {
                        id: selectedObject!.objectId as ObjectId,
                        version: selectedObject!.versionId as VersionId,
                    },
                ],
                {
                    needleThresholdRatio,
                    capMinAngleDegrees,
                    needleCollapseLength,
                },
            );
        }
        onClose();
    }

    return (
        <Dialog
            action={(response) => {
                action(response);
            }}
            dialogTitle={REMOVE_DEGENERATE_TRIANGLES}
            confirmText={REMOVE}
            cancelText={CANCEL}
            open={open}
        >
            <NumericInputParameterRow
                PrefixElements={() => (
                    <>
                        <Typography className={classes.label}>{NEEDLE_THRESHOLD_RATIO}</Typography>
                        <Typography className={classes.oneTo}>{NEEDLE_THRESHOLD_ONE_TO}</Typography>
                    </>
                )}
                value={needleThresholdRatio}
                onChange={setNeedleThresholdRatio}
                min={0}
                exclusiveMin
                tooltipText={NEEDLE_THRESHOLD_RATIO_INFO}
            />
            <NumericInputParameterRow
                PrefixElements={() => (
                    <Typography className={classes.label}>{NEEDLE_COLLAPSE_LENGTH}</Typography>
                )}
                value={needleCollapseLength}
                onChange={setNeedleCollapseLength}
                min={0}
                tooltipText={NEEDLE_COLLAPSE_LENGTH_INFO}
            />
            <NumericInputParameterRow
                PrefixElements={() => (
                    <Typography className={classes.label}>{CAP_MINIMUM_ANGLE}</Typography>
                )}
                value={capMinAngleDegrees}
                onChange={setCapMinAngleDegrees}
                min={90}
                max={180}
                exclusiveMax
                tooltipText={CAP_MINIMUM_ANGLE_INFO}
            />
            <DeprecatedTransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={REMOVING_DEGENERACIES}
            />
        </Dialog>
    );
};
