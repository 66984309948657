import { setShowVolumes, clearVolumes } from 'src/store/project/projectSlice';
import { useAppDispatch } from 'src/store/store';

export function useVolumesManager() {
    const dispatch = useAppDispatch();

    function resetVolumes() {
        dispatch(setShowVolumes(false));
        dispatch(clearVolumes());
    }

    return { resetVolumes };
}
