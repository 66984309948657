import CircularProgress from '@mui/material/CircularProgress';
import MaterialDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import { useStyles } from './ProgressModal.styles';

export interface ProgressModalType {
    open: boolean;
    title: string;
    dialogContent: string;
}

function Title({ title }: { title: string }) {
    return (
        <Typography variant="h5" padding="4px">
            {title}
        </Typography>
    );
}

function Description({ dialogContent }: { dialogContent: string }) {
    const { classes } = useStyles();
    return (
        <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    {dialogContent && (
                        <Typography component="div" classes={{ root: classes.contentText }}>
                            {dialogContent}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </DialogContent>
    );
}

export function ProgressModal({ open, title, dialogContent }: ProgressModalType) {
    const { classes } = useStyles();

    return (
        <MaterialDialog
            open={open}
            maxWidth={false}
            classes={{ paper: classnames(classes.dialogContainer) }}
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                wrap="nowrap"
            >
                <Grid item xs={12}>
                    <Title title={title} />
                    <Grid item container direction="row">
                        <CircularProgress />
                        <Description dialogContent={dialogContent} />
                    </Grid>
                </Grid>
            </Grid>
        </MaterialDialog>
    );
}
