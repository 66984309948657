import { AntSwitch } from '@local/web-design-system/dist/components/AntSwitch';
import { Dialog } from '@local/web-design-system/dist/components/Dialog';
import { NumericInput } from '@local/web-design-system/dist/components/NumericInput';
import { PropertiesSelectMenu } from '@local/web-design-system/dist/components/Properties/PropertiesSelectMenu';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import {
    GtmMeshTransformationAction,
    GtmMeshDetectorAction,
    GtmMeshFillMode,
    GtmMeshFillHoleParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjects } from 'src/store/visualization/selectors';
import { ObjectId, VersionId } from 'src/types/core.types';
import { DeprecatedTransformationProgressModal } from 'src/visualization/TransformationProgressModal/DeprecatedTransformationProgressModal';

import {
    FILL_HOLES,
    FILL_MODE,
    FILL_MODE_INFO,
    SPECIFY_HOLE,
    SPECIFY_HOLE_INFO,
    FILL_REFINE_AND_FAIR,
    FILL_AND_REFINE,
    FILL,
    CANCEL,
    FILLING_HOLES,
} from './FillHoles.constants';
import { useStyles } from './FillHoles.styles';
import { ParameterRow } from './ParameterRow/ParameterRow';

export const FillHolesDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const { classes } = useStyles();
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const selectedObject = Object.values(useAppSelector(selectedSceneObjects))[0];
    const selectedObjectHoles = useAppSelector(
        issueDataForObjectAndAction(selectedObject.objectId, GtmMeshDetectorAction.DetectHoles),
    );

    const [fillMode, setFillMode] = useState<GtmMeshFillMode>(GtmMeshFillMode.Fill);
    const [specifiedHoleIndex, setSpecifiedHoleIndex] = useState<number>(0);
    const [isHoleSelectionDisabled, setIsHoleSelectionDisabled] = useState<boolean>(true);

    function anEdgeForEachHole() {
        return selectedObjectHoles?.map(({ edges }) => edges[0]) ?? [];
    }

    function fillHoleParams(): GtmMeshFillHoleParams {
        if (isHoleSelectionDisabled) {
            return { fillMode, edges: anEdgeForEachHole() };
        }
        return {
            fillMode,
            edges: selectedObjectHoles![specifiedHoleIndex].edges[0],
        };
    }

    async function action(accept: boolean | null) {
        if (accept) {
            await executeTransformation(
                GtmMeshTransformationAction.FillHoles,
                [
                    {
                        id: selectedObject!.objectId as ObjectId,
                        version: selectedObject!.versionId as VersionId,
                    },
                ],
                fillHoleParams(),
            );
        }
        onClose();
    }

    /* The behavior of this dialog is kind of finnicky - should be fixed moving to MUI. */
    return (
        <Dialog
            action={(response) => {
                action(response);
            }}
            dialogTitle={FILL_HOLES}
            confirmText={FILL}
            cancelText={CANCEL}
            open={open}
        >
            <ParameterRow
                ParameterElements={() => (
                    <>
                        <Typography className={classes.label}>{FILL_MODE}</Typography>
                        <PropertiesSelectMenu
                            title=""
                            options={[
                                { key: GtmMeshFillMode.Fill, label: FILL },
                                { key: GtmMeshFillMode.FillAndRefine, label: FILL_AND_REFINE },
                                {
                                    key: GtmMeshFillMode.FillRefineAndFair,
                                    label: FILL_REFINE_AND_FAIR,
                                },
                            ]}
                            onSelect={(newFillMode: string | number) =>
                                setFillMode(newFillMode as GtmMeshFillMode)
                            }
                            fullWidth
                            annotationsClassName={classes.control}
                            disableTitle
                        />
                    </>
                )}
                tooltipText={FILL_MODE_INFO}
            />
            <ParameterRow
                ParameterElements={() => (
                    <>
                        <Typography className={classes.label}>{SPECIFY_HOLE}</Typography>
                        <NumericInput
                            value={specifiedHoleIndex || 0}
                            onChange={(newValue) => setSpecifiedHoleIndex(newValue)}
                            min={0}
                            max={selectedObjectHoles!.length - 1}
                            disabled={isHoleSelectionDisabled}
                            className={classes.control}
                        />
                        <AntSwitch
                            onChange={(_, enabled: boolean) => setIsHoleSelectionDisabled(enabled)}
                            checked={isHoleSelectionDisabled}
                        />
                    </>
                )}
                tooltipText={SPECIFY_HOLE_INFO}
            />
            <DeprecatedTransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={FILLING_HOLES}
            />
        </Dialog>
    );
};
