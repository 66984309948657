import { useDispatch } from 'react-redux';

import { GtmProject } from 'src/gtmProject/Project.types';
import { useDeleteFile } from 'src/hooks/evoContext/useDeleteFile';
import { useLazyFetchGtmProjectFile } from 'src/hooks/evoContext/useLazyFetchGtmProjectFile';
import { useLazyUploadFile } from 'src/hooks/evoContext/useLazyUploadFile';
import { createFileFromProject } from 'src/hooks/utils';
import { clearCurrentProject, overwriteProject } from 'src/store/project/projectSlice';
import { clearSceneObjects } from 'src/store/visualization/visualizationSlice';
import { PROJECT_EXTENSION } from 'src/strings';

export function useProjectManager() {
    const dispatch = useDispatch();
    const [FetchGtmProjectFileTrigger] = useLazyFetchGtmProjectFile();
    const [UploadFileTrigger] = useLazyUploadFile();
    const [DeleteFileTrigger] = useDeleteFile();

    async function initializeNewProject(newName: string, description?: string) {
        const newProject = {
            name: newName,
            description,
            inputs: [],
            history: [],
            models: [],
        };
        const newProjectFile = createFileFromProject(newProject);

        const resp = await UploadFileTrigger(newProjectFile);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        dispatch(clearCurrentProject());
        dispatch(clearSceneObjects());
        return resp.data;
    }

    async function deleteProject(projectName: string) {
        const resp = await DeleteFileTrigger(`${projectName}.${PROJECT_EXTENSION}`);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        dispatch(clearCurrentProject());
        dispatch(clearSceneObjects());
        return resp.data;
    }

    /**
     * File service does not support renaming files. This is a workaround to renaming a project by creating a project copy and deleting the old project.
     */
    async function renameProject(project: GtmProject, newName: string) {
        const oldProjectName = project.name;
        const newProjectFile = createFileFromProject({
            ...project,
            name: newName,
        });
        const resp = await UploadFileTrigger(newProjectFile);
        if ('error' in resp) {
            return Promise.reject(resp.error);
        }

        try {
            await deleteProject(oldProjectName);
            return resp.data;
        } catch (e) {
            // Rename failed due to error deleting old project after creating a new one. Try deleting the new project
            await deleteProject(newName);
            return Promise.reject(e);
        }
    }

    async function loadProjectFromFile(file: string) {
        dispatch(clearCurrentProject());
        dispatch(clearSceneObjects());

        const fileData = await FetchGtmProjectFileTrigger(file);
        if (fileData) {
            dispatch(overwriteProject({ project: fileData }));
            return fileData;
        }

        return Promise.reject(new Error('Failed to load project'));
    }

    return {
        initializeNewProject,
        loadProjectFromFile,
        deleteProject,
        renameProject,
    };
}
