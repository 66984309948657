import { Skeleton } from '@local/web-design-system/dist/components/Skeleton';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { useSceneObjectSelectionManager } from 'src/hooks/useSceneObjectSelectionManager';
import { selectUserTriangleMeshes } from 'src/store/evo/selectors';
import {
    selectCurrentProjectAnalyticalModelObjects,
    selectCurrentProjectName,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';
import { BASE_MODEL } from 'src/visualization/Visualization/ArtifactsPanel/ArtifactsPanel.constants';

import { AggregateTreePanel } from './Aggregate/AggregateTreePanel';
import { ObjectListItemControl } from './ObjectListItemControl/ObjectListItemControl';
import { useStyles } from './ProjectTreePanel.styles';
import { UploadObjectsButton } from './UploadObjectsButton/UploadObjectsButton';

export function SkeletonArtifactsPanelContents() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.content}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Grid>
    );
}

export function ProjectTreePanel() {
    const { classes } = useStyles();
    const currentObjects = useAppSelector(selectCurrentProjectAnalyticalModelObjects);
    const { projectName } = useParams();
    const selectedObjectIds = useAppSelector(selectedSceneObjectIds);
    const currentProjectName = useAppSelector(selectCurrentProjectName);
    const { clearGtmObjects } = useSceneObjectDataManager();
    const { onObjectControlSelection } = useSceneObjectSelectionManager();
    const userMeshes = useAppSelector(selectUserTriangleMeshes);

    useEffect(() => {
        if (projectName !== currentProjectName) {
            clearGtmObjects();
        }
    }, [projectName]);

    return (
        <Grid item xs className={classnames(classes.contentsContainer, classes.content)}>
            <Grid item automation-id="upload-obj-files">
                <UploadObjectsButton buttonText="Upload objects" disabled={false} />
            </Grid>
            <AggregateTreePanel />

            {projectName === BASE_MODEL &&
                (userMeshes ? (
                    userMeshes.map((child: GtmEvoOutputObject, index: number) => (
                        <Grid
                            key={child.id}
                            onClick={(event) =>
                                onObjectControlSelection(userMeshes, index, child.id, event)
                            }
                        >
                            <ObjectListItemControl
                                objectListItem={child}
                                selectedObjectIds={selectedObjectIds}
                                addToSceneOnMount
                            />
                        </Grid>
                    ))
                ) : (
                    <SkeletonArtifactsPanelContents />
                ))}
            {projectName !== BASE_MODEL &&
                currentObjects.map((child: GtmEvoOutputObject, index: number) => (
                    <Grid
                        key={child.id}
                        onClick={(event) =>
                            onObjectControlSelection(currentObjects, index, child.id, event)
                        }
                    >
                        <ObjectListItemControl
                            objectListItem={child}
                            selectedObjectIds={selectedObjectIds}
                            addToSceneOnMount
                        />
                    </Grid>
                ))}
        </Grid>
    );
}
