import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import { useContext } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AggregateIcon } from 'src/assets/AggregateIcon';
import { UseNewUIContext } from 'src/components/contentScaffolding/ContentScaffolding';
import { DEFAULT_TOLERANCE } from 'src/constants';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import {
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import { selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { DeprecatedTransformationProgressModal } from 'src/visualization/TransformationProgressModal/DeprecatedTransformationProgressModal';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

import {
    AGGREATION_SUCCESS_MESSAGE,
    AGGREGATING_GEOM_MESSAGE,
    AGGREGATION_FAILURE_MESSAGE,
} from './AggregateControl.constants';
import { useStyles } from './ObjectListItemControl.styles';

export function AggregateControl({
    inputMesh,
    handleRemoveObject,
}: {
    inputMesh: GtmEvoOutputObject;
    handleRemoveObject: () => void;
}) {
    const applyTrace = useTrace('aggregate-control');
    const { classes } = useStyles();
    const { executeTransformation, transformationStatus } = useTransformationManager();
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const { resetVolumes } = useVolumesManager();
    const { findObjectAndSetAggregated } = useObjectManager();
    const shouldUseNewUI = useContext(UseNewUIContext);

    const performAggregation = async () => {
        await executeTransformation(
            GtmMeshTransformationAction.AddToAggregateGeom,
            [inputMesh],
            {
                aggregateGeomId: currentAggregateGeometry!,
                tolerance: DEFAULT_TOLERANCE,
                noSelfIntersectionsInParts: true,
            },
            {
                handleAdditionalSideEffects: (_) => {
                    // Adding a surface to the aggregate invalidates the
                    // volumes that were computed from the previous aggregate.
                    resetVolumes();

                    // Once a surface is aggregated, we want to mark it as such and hide it.
                    findObjectAndSetAggregated(inputMesh.id, true);
                    handleRemoveObject();
                },
            },
        );
    };

    if (shouldUseNewUI) {
        const titlesByStatus = new Map<TransformationStatus, string>([
            [TransformationStatus.Transforming, AGGREGATING_GEOM_MESSAGE],
            [TransformationStatus.Uploading, AGGREGATING_GEOM_MESSAGE],
            [TransformationStatus.Complete, AGGREATION_SUCCESS_MESSAGE],
            [TransformationStatus.Failed, AGGREGATION_FAILURE_MESSAGE],
        ]);
        const percentagesByStatus = new Map<TransformationStatus, number>([
            [TransformationStatus.Transforming, 0],
            [TransformationStatus.Uploading, 70],
            [TransformationStatus.Complete, 100],
            [TransformationStatus.Failed, 100],
        ]);
        return (
            <>
                <IconButton
                    disableRipple
                    edge="end"
                    size="small"
                    onClick={performAggregation}
                    automation-id={applyTrace('perform-aggregation')}
                >
                    <AggregateIcon />
                </IconButton>
                <TransformationProgressModal
                    transformationStatus={transformationStatus}
                    transformationTitles={titlesByStatus}
                    transformationPercentages={percentagesByStatus}
                />
            </>
        );
    }

    return (
        <>
            <Tooltip title="Add to Aggregate" placement="top" arrow enterDelay={0}>
                <IconButton
                    onClick={performAggregation}
                    className={classnames(classes.removeIconButton)}
                >
                    <ViewInArIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <DeprecatedTransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={AGGREGATING_GEOM_MESSAGE}
            />
        </>
    );
}
