import { selectCurrentModel, selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { ModelSettingsPanel } from 'src/visualization/SettingsPanel/ModelSettingsPanel';

export function SettingsPanel() {
    const currentModel = useAppSelector(selectCurrentModel);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);

    if (currentModelSelectedObject) {
        // TODO: GEOM-478 return object properties panel
        return null;
    }

    if (currentModel) {
        return <ModelSettingsPanel />;
    }

    return null;
}
