import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import PlusIcon from '@local/web-design-system/dist/icons/Actions/PlusIcon';
import { DefaultObjectIcon } from '@local/web-design-system/dist/icons/Artifacts/DefaultObjectIcon';
import { MeshIcon } from '@local/web-design-system/dist/icons/Artifacts/MeshIcon';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { useDefectsLoadingManager } from 'src/hooks/defects/useDefectsLoadingManager';
import { issuesMapForObject } from 'src/store/issues/selectors';
import {
    selectCurrentAggregateGeometry,
    selectCurrentProjectAnalyticalModelObjects,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { sceneObjectById } from 'src/store/visualization/selectors';
import { fileNameExtensionRemover } from 'src/utils';
import { countAllIssues } from 'src/utils/countIssues';
import { gtmColorToRGBArray } from 'src/utils/typeTransformations';

import { useSceneObjectDataManager } from '../../../../../hooks/useSceneObjectDataManager';
import { useStyles } from './ObjectListItemControl.styles';
import { ObjectListItemControlProps } from './ObjectListItemControl.types';
import { StandardObjectOnPlotButtons } from './StandardObjectOnPlotButtons';

const APPROXIMATE_MAX_LOAD_TIME = 15000;

export enum Schemas {
    TriangleMeshSchema = 'triangle-mesh.schema.json',
}

export const SchemaIcon = (schema: string) => {
    const iconsDictionary = {
        [Schemas.TriangleMeshSchema]: MeshIcon,
    };

    const gooseType = extractSchema(schema);
    if (gooseType in iconsDictionary) {
        return iconsDictionary[gooseType as keyof typeof iconsDictionary];
    }

    return DefaultObjectIcon;
};

const extractSchema = (fullSchema: string | null) => {
    if (!fullSchema) {
        return '';
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase();
};

export function ObjectListItemControl({
    objectListItem,
    selectedObjectIds,
    addToSceneOnMount,
}: ObjectListItemControlProps) {
    const { classes } = useStyles();
    const applyTrace = useTrace('object-list-item');
    const { getZoomToViewTool } = useBaseXyz();
    const { runAllDetectors } = useDefectsLoadingManager();

    const { id: objectId, version, name, schema, color } = objectListItem;

    const issues = useAppSelector(issuesMapForObject(objectId));
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry)!;
    const currentAnalyticalModelObjects = useAppSelector(
        selectCurrentProjectAnalyticalModelObjects,
    );

    const itemControlRef = useRef<HTMLDivElement>(null);

    const {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        useViewListener,
        updateObjectColor,
    } = useSceneObjectDataManager();
    const objectData = useAppSelector(sceneObjectById(objectId));

    const { isViewLoading, isViewError } = useViewListener(objectId);

    const isObjectOnPlot = isObjectOnPlotByObjectId(objectId);

    const handleAddObject = async () => {
        await loadGtmObject(objectId, version, name);
        if (color) {
            updateObjectColor(objectId, gtmColorToRGBArray(color));
        }
    };
    const initialLoad = useCallback(async () => {
        if (objectId && version && addToSceneOnMount) {
            handleAddObject();
        }
        if (objectId && version) {
            runAllDetectors({ id: objectListItem.id, version: objectListItem.version });
        }
    }, [objectId, version, color, name]);

    useEffect(() => {
        initialLoad();
    }, [initialLoad]);

    const handleRemoveObject = () => {
        removeGtmObject(objectId);
    };

    function handleZoomToView() {
        getZoomToViewTool().zoomToView(objectId);
    }

    const Icon = SchemaIcon(schema);

    const defectCount = issues ? countAllIssues(issues) : 0;
    const showDefectWarning = defectCount > 0;
    const modelObject = currentAnalyticalModelObjects?.find((object) => object.id === objectId);
    const canBeAggregated = !!modelObject;
    const isAggregate = currentAggregateGeometry?.id === objectId;
    const canBeRemeshed = !modelObject?.isAggregated || isAggregate;
    const isObjectLoading = objectData?.isLoading || isViewLoading;

    return (
        <Grid
            item
            container
            className={classnames(classes.root, {
                [classes.errorContainer]: false,
                [classes.disabledItem]: modelObject?.isAggregated && !isAggregate,
            })}
        >
            <Grid
                className={classnames(classes.background, {
                    [classes.backgroundPending]: isObjectLoading,
                    [classes.backgroundClear]: objectData?.isSuccess || isViewError,
                    [classes.backgroundSelectedForLoading]: selectedObjectIds?.includes(objectId),
                })}
                ref={itemControlRef}
            />
            <Grid
                item
                container
                className={classes.objectListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    zeroMinWidth
                    className={classnames(classes.mainContainer, {
                        [classes.mainContainerOutlined]: false,
                    })}
                    alignItems="center"
                >
                    <Grid
                        item
                        container
                        xs
                        alignItems="stretch"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        automation-id={applyTrace(name)}
                    >
                        <Grid item className={classes.icon}>
                            <Icon />
                        </Grid>
                        <Typography variant="body2" color="inherit" className={classes.name}>
                            {fileNameExtensionRemover(name)}
                        </Typography>
                    </Grid>
                    {isObjectLoading && (
                        <Grid className={classes.progressContainer}>
                            <LinearProgressFaked
                                isLoading={isObjectLoading}
                                milliseconds={APPROXIMATE_MAX_LOAD_TIME}
                                hideWhenNotLoading
                                classes={{ root: classes.loadingProgressContainer }}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item className={classes.loadButtonContainer}>
                    {!isObjectOnPlot && !modelObject?.isAggregated && (
                        <Tooltip title="Add into scene" placement="top" arrow enterDelay={0}>
                            <IconButton
                                onClick={handleAddObject}
                                className={classnames(classes.loadIconButton)}
                            >
                                <PlusIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isObjectOnPlot && !isObjectLoading && !modelObject?.isAggregated && (
                        <StandardObjectOnPlotButtons
                            isAggregate={isAggregate}
                            canBeAggregated={canBeAggregated}
                            canBeRemeshed={canBeRemeshed}
                            objectListItem={objectListItem}
                            currentAggregateGeometry={currentAggregateGeometry}
                            objectId={objectId}
                            showDefectWarning={showDefectWarning}
                            defectCount={defectCount}
                            handleRemoveObject={handleRemoveObject}
                            handleZoomToView={handleZoomToView}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
