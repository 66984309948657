import MinusIcon from '@local/web-design-system/dist/icons/Actions/MinusIcon';
import WarningIcon from '@local/web-design-system/dist/icons/Alerts/WarningIcon';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classnames from 'classnames';

import { GtmEvoOutputObject } from 'src/gtmProject';

import { AggregateControl } from './AggregateControl';
import { ObjectActionMenu } from './ObjectActionMenu';
import { useStyles } from './ObjectListItemControl.styles';
import { WireframeControl } from './PropertiesToggleControl';
import { RemeshControl } from './RemeshControl';
import { ResetAggregateControl } from './ResetAggregateControl';

interface StandardObjectOnPlotButtonsProps {
    canBeAggregated: boolean;
    canBeRemeshed: boolean;
    isAggregate: boolean;
    objectListItem: GtmEvoOutputObject;
    currentAggregateGeometry: GtmEvoOutputObject;
    objectId: string;
    showDefectWarning: boolean;
    defectCount: number;
    handleRemoveObject: () => void;
    handleZoomToView: () => void;
}

export const StandardObjectOnPlotButtons = ({
    canBeAggregated,
    canBeRemeshed,
    isAggregate,
    objectListItem,
    objectId,
    showDefectWarning,
    defectCount,
    handleRemoveObject,
    handleZoomToView,
}: Readonly<StandardObjectOnPlotButtonsProps>) => {
    const { classes } = useStyles();
    return (
        <Grid item container xs alignItems="stretch" wrap="nowrap">
            {canBeAggregated && (
                <AggregateControl
                    inputMesh={objectListItem}
                    handleRemoveObject={handleRemoveObject}
                />
            )}
            {isAggregate && <ResetAggregateControl />}
            {canBeRemeshed && <RemeshControl inputMesh={objectListItem} />}
            <WireframeControl viewId={objectId} />
            {showDefectWarning && (
                <Tooltip
                    title={`${defectCount} issue(s) detected`}
                    placement="top"
                    arrow
                    enterDelay={0}
                >
                    <Grid className={classnames(classes.warningIconStyle)}>
                        <WarningIcon color="warning" fontSize="inherit" />
                    </Grid>
                </Tooltip>
            )}
            <Tooltip title="Remove from scene" placement="top" arrow enterDelay={0}>
                <IconButton
                    onClick={handleRemoveObject}
                    className={classnames(classes.removeIconButton)}
                >
                    <MinusIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <ObjectActionMenu
                onRemoveItem={handleRemoveObject}
                onZoomToView={handleZoomToView}
                zoomToViewActive
            />
        </Grid>
    );
};
