import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const styles = (theme: Theme) =>
    ({
        control: {
            marginLeft: theme.spacing(1),
        },
        label: {
            whiteSpace: 'nowrap',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
