import { createSelector } from '@reduxjs/toolkit';

import {
    GtmMeshDetectorAction,
    GtmMeshDetectionData,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    DEFAULT_TOLERANCE,
    DEFAULT_NEEDLETHRESHOLDRATIO,
    DEFAULT_CAPMINANGLEDEGREES,
    DEFAULT_HOLESIZERATIOTOLERANCE,
} from 'src/constants';

import type { RootState } from '../store';
import {
    IssuesState,
    IssuesMap,
    IssueInfo,
    IssuesObjectMap,
    DetectorSettingsMap,
} from './issuesSlice.types';

type SelectorTypeIssuesObjectMap = (state: RootState) => IssuesObjectMap | undefined;
type SelectorTypeIssuesMap = (state: RootState) => IssuesMap | undefined;
type SelectorTypeIssueInfo = (state: RootState) => IssueInfo | undefined;
type SelectorTypeDetectionData = (state: RootState) => GtmMeshDetectionData | undefined;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeDetectorSettingsMap = (state: RootState) => DetectorSettingsMap;

export const initialState: IssuesState = {
    issuesMap: {},
    settingsMap: {
        [GtmMeshDetectorAction.DetectDegenerateTris]: {
            // Default needle threshold loosely linked to cap definition: for an isoceles cap triangle at the
            // threshold we have that length-of-base / cap-height = 89.5, hence rounded up we set the ratio to 100.
            needleThresholdRatio: DEFAULT_NEEDLETHRESHOLDRATIO,
            capMinAngleDegrees: DEFAULT_CAPMINANGLEDEGREES,
        },
        [GtmMeshDetectorAction.DetectDuplicatePoints]: {},
        [GtmMeshDetectorAction.DetectDuplicateTris]: {},
        [GtmMeshDetectorAction.DetectFins]: {},
        [GtmMeshDetectorAction.DetectHoles]: {
            holeSizeRatioTolerance: DEFAULT_HOLESIZERATIOTOLERANCE,
        },
        [GtmMeshDetectorAction.DetectInconsistentlyOrientedTris]: {},
        [GtmMeshDetectorAction.DetectNonManifoldEdges]: {},
        [GtmMeshDetectorAction.DetectNonManifoldVertices]: {},
        [GtmMeshDetectorAction.DetectNonPartitioningSurfaces]: {},
        [GtmMeshDetectorAction.DetectSelfIntersections]: { tolerance: DEFAULT_TOLERANCE },
    },
};

const issuesState = (state: RootState): IssuesState => state.issues ?? initialState;

export const issuesMapForAllObjects: SelectorTypeIssuesObjectMap = createSelector(
    issuesState,
    (issuesStateRoot) => issuesStateRoot.issuesMap,
);

export const issuesMapForObject = (objectId: string): SelectorTypeIssuesMap =>
    createSelector(issuesMapForAllObjects, (issuesObjectMap) => issuesObjectMap?.[objectId]);

export const issueForObjectAndAction = (
    objectId: string,
    action: GtmMeshDetectorAction,
): SelectorTypeIssueInfo =>
    createSelector(issuesMapForObject(objectId), (issuesMap) => issuesMap?.[action]);

export const issueDataForObjectAndAction = (
    objectId: string,
    action: GtmMeshDetectorAction,
): SelectorTypeDetectionData =>
    createSelector(issueForObjectAndAction(objectId, action), (issue) => issue?.data);

export const anyIssueIsStillLoading = (objectId: string): SelectorTypeBoolean =>
    createSelector(issuesMapForObject(objectId), (issuesMap) =>
        Object.values(issuesMap ?? {}).some((issue) => issue.isLoading),
    );

export const detectorSettingsMap: SelectorTypeDetectorSettingsMap = createSelector(
    issuesState,
    (issuesStateRoot) => issuesStateRoot.settingsMap,
);
