import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useEvoContextManager } from 'src/hooks/evoContext/useEvoContextManager';
import { useProjectManager } from 'src/hooks/project';
import { setSelectedModelIndex } from 'src/store/project/projectSlice';
import { useAppDispatch } from 'src/store/store';
import { BASE_MODEL } from 'src/visualization/Visualization/ArtifactsPanel/ArtifactsPanel.constants';

export const UrlListener: React.FC = () => {
    const dispatch = useAppDispatch();
    const { projectName, workspaceUuid, orgUuid, hubCode } = useParams();
    const navigate = useNavigate();
    const { loadProjectFromFile } = useProjectManager();
    const { loadWorkspaceTriangleMeshes } = useEvoContextManager();

    // The base model stuff is a bit hacky, and is here to support the "old design".
    // The logic can be simplified when we switch to the new design.
    useEffect(() => {
        if (projectName) {
            if (projectName === BASE_MODEL) {
                loadWorkspaceTriangleMeshes();
            } else {
                loadProjectFromFile(projectName);
                // TEMP: this dispatch is to support the old design. Should be removed after the move to the new UI
                // This is to ensure the analytical model is loaded when a project is loaded
                dispatch(setSelectedModelIndex(0));
            }
        } else if (!projectName && workspaceUuid && orgUuid && hubCode) {
            navigate(`/${orgUuid}/hub/${hubCode}/workspace/${workspaceUuid}/project/${BASE_MODEL}`);
        }
    }, [projectName]);

    return null;
};
