import { LinearProgressFaked } from '@local/web-design-system/dist/components/Progress/LinearProgressFaked';
import { UploadIcon } from '@local/web-design-system/dist/icons/Files/UploadIcon';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import { useRef, useState } from 'react';

import { useObjToTriangleMeshUploader } from 'src/hooks/evoContext/useObjToTriangleMeshUploader';

import { useStyles } from './UploadObjectsButton.styles';

const APPROXIMATE_UPLOAD_TIME = 10000;

export const UploadObjectsButton = ({
    buttonText,
    disabled,
    secondary = false,
}: {
    buttonText: string;
    disabled?: boolean;
    secondary?: boolean;
}) => {
    const { classes } = useStyles();
    const uploadFieldRef = useRef<HTMLInputElement>(null);
    const { uploadObjAsTriangleMesh } = useObjToTriangleMeshUploader();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentFiles = event.currentTarget.files;
        if (!currentFiles) {
            return;
        }

        setIsUploading(true);
        const uploadPromises = Array.from(currentFiles).map(async (file) => {
            if (uploadFieldRef.current) {
                uploadFieldRef.current.value = '';
            }

            return uploadObjAsTriangleMesh(file);
        });

        // Add as many artifacts as possible, even if some fail to upload. Using `Promise.all` would
        // stop at the first error.
        await Promise.allSettled(uploadPromises);
        setIsUploading(false);
    };

    return (
        <Button
            disabled={disabled || isUploading}
            component="label"
            color={secondary ? 'inherit' : 'primary'}
            className={secondary ? classes.lightButton : undefined}
            variant="contained"
            automation-id="upload-objects-button"
            fullWidth
        >
            <Grid container className={secondary ? classes.secondaryButton : ''}>
                <Grid className={classes.buttonText}>
                    <UploadIcon fontSize="small" />
                    {buttonText}
                </Grid>
                <input
                    ref={uploadFieldRef}
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    onChange={handleFileChange}
                    accept=".obj"
                />
                {isUploading && (
                    <LinearProgressFaked
                        isLoading={isUploading}
                        milliseconds={APPROXIMATE_UPLOAD_TIME}
                        hideWhenNotLoading
                        classes={{ root: classes.loadingProgressContainer }}
                    />
                )}
            </Grid>
        </Button>
    );
};
