import { useLazyGetObjectByIdQuery } from '@local/api-clients/src/goose/enhancedGooseClient';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { createSnapshotToUpdateColorAndBackColor } from '@local/webviz/dist/context/snapshots';
import { Color as XyzColor } from '@local/webviz/dist/types/xyz';
import { useEffect, useState } from 'react';

import { useDefectsVisualizationManager } from 'src/hooks/defects';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { sceneObjectMap } from 'src/store/visualization/selectors';
import {
    addOrUpdateSceneObject,
    clearSceneObjects,
} from 'src/store/visualization/visualizationSlice';
import { gooseToGtmBoundingBox } from 'src/utils/typeTransformations';
import { getGlobalBoundingBox, BoundingBox } from 'src/utils/xyzUtils';
import { getMeshSnapshot, updateSurfaceMeshData } from 'src/visualization/context/generateData';

import { useGooseContext } from './useGooseContext';

export function useSceneObjectDataManager() {
    const dispatch = useAppDispatch();
    const sceneObjects = useAppSelector(sceneObjectMap);
    const [GetGooseObjectTrigger] = useLazyGetObjectByIdQuery();
    const { removeIssuesForObject, removeHighlightsForObject } = useDefectsVisualizationManager();
    const gooseContext = useGooseContext();

    const {
        useXyzListener,
        addViewStatusListener,
        setStateFromSnapshot,
        removeViewsFromPlotDirectly,
        addViewToPlotDirectly,
        getState,
    } = useBaseXyz();

    async function loadGtmObject(objectId: string, versionId: string, name: string = '') {
        if (
            sceneObjects[objectId]?.versionId === versionId &&
            !isObjectOnPlotByObjectId(objectId)
        ) {
            addViewToPlotDirectly(`${objectId}`);
        } else {
            const elementId = `${objectId}-element`;
            setStateFromSnapshot(getMeshSnapshot(elementId, `${objectId}`), {});
            addViewToPlotDirectly(`${objectId}`);

            dispatch(
                addOrUpdateSceneObject([objectId, { isLoading: true, name, objectId, versionId }]),
            );

            const {
                data: objectData,
                isSuccess: gooseObjectSuccess,
                isLoading: gooseObjectLoading,
            } = await GetGooseObjectTrigger({
                objectId,
                version: versionId,
                workspaceId: gooseContext!.workspaceId,
                orgId: gooseContext!.orgId,
            });

            if (gooseObjectSuccess && !gooseObjectLoading) {
                dispatch(
                    addOrUpdateSceneObject([
                        objectId,
                        {
                            name: objectData.object.name,
                            gooseObject: objectData,
                        },
                    ]),
                );
                setStateFromSnapshot(updateSurfaceMeshData(elementId, objectData), {});
            }
        }
    }

    function updateObjectColor(objectId: string, color: XyzColor) {
        const colorUpdateSnapshot = createSnapshotToUpdateColorAndBackColor(
            `${objectId}`,
            color,
            color,
        );
        setStateFromSnapshot(colorUpdateSnapshot, {});
    }

    function removeGtmObject(objectId: string) {
        removeViewsFromPlotDirectly([objectId]);
        removeIssuesForObject(objectId, undefined);
        removeHighlightsForObject(objectId, undefined);
    }

    function clearGtmObjects() {
        setStateFromSnapshot(
            { plot: { views: [] }, selector: { active: false, primitiveId: -1 } },
            {},
        );
        dispatch(clearSceneObjects());
    }

    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    function useViewListener(listItemId: string) {
        const [isViewLoading, setIsViewLoading] = useState(false);
        const [isViewError, setIsViewError] = useState(false);

        useEffect(() => {
            const objectViewId = getViewIdOnPlotByObjectId(listItemId);
            if (!objectViewId) return () => {};
            const removeViewStatusListener = addViewStatusListener({
                viewId: objectViewId,
                onComplete: () => {
                    setIsViewLoading(false);
                    setIsViewError(false);
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: false,
                                isSuccess: true,
                            },
                        ]),
                    );
                },
                onError: (_failedKey: string) => {
                    setIsViewLoading(false);
                    setIsViewError(true);
                    console.error('Error loading object');
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: false,
                                isSuccess: false,
                            },
                        ]),
                    );
                },
                onPending: () => {
                    setIsViewLoading(true);
                    setIsViewError(false);
                    dispatch(
                        addOrUpdateSceneObject([
                            objectViewId,
                            {
                                isLoading: true,
                            },
                        ]),
                    );
                },
            });
            return () => {
                removeViewStatusListener();
            };
        }, [views]);

        return {
            isViewLoading,
            isViewError,
        };
    }

    function isObjectOnPlotByObjectId(objectId: string): boolean {
        return getState().plot?.views?.some((view: string) => view.includes(objectId));
    }

    function getViewIdOnPlotByObjectId(objectId: string): string | undefined {
        return getState().plot?.views?.find((view: string) => view.includes(objectId));
    }

    function getPlotObjectsBoundingBox(): BoundingBox | undefined {
        const boundingBoxes = Object.values(sceneObjects)
            .filter(
                (sceneObjectData) =>
                    isObjectOnPlotByObjectId(sceneObjectData?.objectId) &&
                    sceneObjectData?.gooseObject?.object?.bounding_box,
            )
            .map((sceneObjectData) =>
                gooseToGtmBoundingBox(sceneObjectData?.gooseObject?.object?.bounding_box),
            );

        return getGlobalBoundingBox(boundingBoxes);
    }

    return {
        loadGtmObject,
        removeGtmObject,
        isObjectOnPlotByObjectId,
        useViewListener,
        clearGtmObjects,
        updateObjectColor,
        getPlotObjectsBoundingBox,
    };
}
