import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
    ADD_LABEL,
    DELETE_LABEL,
    RENAME_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function PanelItemMenu({
    anchorEl,
    onClose,
    onDelete,
    onRename,
    onAdd,
}: {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    onDelete?: () => void;
    onRename?: () => void;
    onAdd?: () => void;
}) {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            onClick={onClose}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {onAdd && (
                <MenuItem onClick={onAdd}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" />
                    </ListItemIcon>
                    {ADD_LABEL}
                </MenuItem>
            )}
            {onRename && (
                <MenuItem onClick={onRename}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    {RENAME_LABEL}
                </MenuItem>
            )}
            {onDelete && (
                <MenuItem onClick={onDelete}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    {DELETE_LABEL}
                </MenuItem>
            )}
        </Menu>
    );
}
