import {
    DockableContainer,
    DockPosition,
} from '@local/web-design-system/dist/components/DockableContainer';
import { MeasurementDialog } from '@local/webviz/dist/components/Measurement';
import { MEASUREMENT_TITLE } from '@local/webviz/dist/components/Measurement/MeasurementDialog.constants';
import { Settings, SETTINGS_TITLE } from '@local/webviz/dist/components/Settings';
import { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import {
    SLICE,
    DockableSliceDialog as SliceDialog,
} from '@local/webviz/dist/components/Slice/SliceDialog';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import { orientationVisible, scalebarVisible } from 'src/store/visualization/selectors';
import { updateOverlays } from 'src/store/visualization/visualizationSlice';

import { AggregateDialog } from './AggregateDialog/AggregateDialog';
import { PARAM_TITLE } from './AggregateDialog/AggregateDialog.constants';
import { DefectsDialog } from './DefectsDialog/DefectsDialog';
import { ISSUES_TITLE } from './DefectsDialog/DefectsDialog.constants';
import { DockableDialogProps, DockedWindows } from './DockableDialogs.types';

const DOCK_THRESHOLD = 25;

export function DockableSliceDialog({ onClose, defaultPosition }: DockableDialogProps) {
    return (
        <SliceDialog
            header={{ title: SLICE, onClose }}
            type={DockedWindows.Slice}
            dockPosition={DockPosition.MIDDLE}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        />
    );
}

export function DockableDefectsDialog({ onClose, defaultPosition }: DockableDialogProps) {
    return (
        <DockableContainer
            header={{ title: ISSUES_TITLE, onClose }}
            type={DockedWindows.Defects}
            dockPosition={DockPosition.TOP}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
            isDockedPosition
        >
            <DefectsDialog />
        </DockableContainer>
    );
}

export function DockableAggregateDialog({ onClose, defaultPosition }: DockableDialogProps) {
    return (
        <DockableContainer
            header={{ title: PARAM_TITLE, onClose }}
            type={DockedWindows.Aggregate}
            dockPosition={DockPosition.UPPER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
            isDockedPosition
        >
            <AggregateDialog />
        </DockableContainer>
    );
}

export function DockableMeasureDialog({ onClose, defaultPosition }: DockableDialogProps) {
    return (
        <DockableContainer
            header={{ title: MEASUREMENT_TITLE, onClose }}
            type={DockedWindows.Measure}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <MeasurementDialog />
        </DockableContainer>
    );
}

export function DockableSettingsDialog({ onClose, defaultPosition }: DockableDialogProps) {
    const isOrientationChecked = useAppSelector(orientationVisible);
    const isScalebarChecked = useAppSelector(scalebarVisible);
    const dispatch = useAppDispatch();
    const updateOverlaysDispatch = (overlays: Partial<SettingsVisualizationOverlays>) =>
        dispatch(updateOverlays(overlays));
    const { getEntityState } = useBaseXyz();
    const plotState = getEntityState('plot');
    let numberOfObjects = 0;
    if (plotState && 'views' in plotState) {
        numberOfObjects = plotState.views.length;
    }

    return (
        <DockableContainer
            type={DockedWindows.Settings}
            header={{ title: SETTINGS_TITLE, onClose }}
            dockPosition={DockPosition.LOWER}
            defaultPosition={defaultPosition}
            dockThreshold={DOCK_THRESHOLD}
        >
            <Settings
                isOrientationChecked={isOrientationChecked}
                isScalebarChecked={isScalebarChecked}
                numberOfObjects={numberOfObjects}
                updateOverlays={updateOverlaysDispatch}
            />
        </DockableContainer>
    );
}
