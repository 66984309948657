import ElementsIcon from '@local/web-design-system/dist/icons/Menu/ElementsIcon';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { MESH_SCHEMA } from 'src/constants';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { setShowVolumes, addVolume } from 'src/store/project/projectSlice';
import {
    selectCurrentProjectVolumes,
    selectCurrentShowVolumes,
    selectCurrentAggregateGeometry,
} from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import { decorateNewObject } from 'src/utils/decorateObject';

import { PARAM_BUTTON_LABEL, PARAM_MESSAGE, PARAM_TITLE } from './AggregateDialog.constants';
import { useStyles } from './AggregateDialog.styles';
import { ParameterizeModelDialog } from './ParameterizeModelDialog';

export const AggregateDialog = () => {
    const { classes } = useStyles();
    const { executeTransformation } = useTransformationManager();

    const [showParamDialog, setShowParamDialog] = useState(false);
    const currentShowVolumes = useAppSelector(selectCurrentShowVolumes);
    const currentProjectVolumes = useAppSelector(selectCurrentProjectVolumes);
    const dispatch = useAppDispatch();
    const aggregateGeometryObject = useAppSelector(selectCurrentAggregateGeometry);

    async function handleOnChange() {
        const newShowVolumes = !currentShowVolumes;
        const hasVolumes = currentProjectVolumes && currentProjectVolumes.length > 0;
        dispatch(setShowVolumes(newShowVolumes));

        if (newShowVolumes && !hasVolumes) {
            await executeTransformation(
                GtmMeshTransformationAction.SeparateVolumes,
                [aggregateGeometryObject!],
                {},
                {
                    createdObjectsHandler: (createdObjects) => {
                        createdObjects.forEach((obj, index) => {
                            dispatch(
                                addVolume(
                                    decorateNewObject(obj, `Volume ${index + 1}`, MESH_SCHEMA),
                                ),
                            );
                        });
                    },
                },
            );
        }
    }

    return (
        <>
            <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                width="100%"
                alignItems="baseline"
                className={classes.root}
            >
                <Divider />
                <FormControlLabel
                    label="Volumes"
                    labelPlacement="start"
                    control={<Switch checked={currentShowVolumes} onChange={handleOnChange} />}
                />
                <Divider />
                <Grid container item xs direction="row" width="100%">
                    <ElementsIcon className={classes.paramIcon} />
                    <Typography className={classes.titleFont}>{PARAM_TITLE}</Typography>
                </Grid>
                <Typography className={classes.messageFont}>{PARAM_MESSAGE}</Typography>
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setShowParamDialog(true)}
                        >
                            {PARAM_BUTTON_LABEL}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <ParameterizeModelDialog
                open={showParamDialog}
                onClose={() => setShowParamDialog(false)}
            />
        </>
    );
};
