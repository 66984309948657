import { useState } from 'react';

import {
    formGtmMeshTransformationBody,
    useLazyGtmMeshTransformationQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshTransformationAction,
    GtmMeshTransformationParams,
    GtmMeshTransformationResponse,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useHistoryManger } from 'src/hooks/history/useHistoryManger';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { ObjectIdWithVersion } from 'src/types/core.types';

export enum TransformationStatus {
    Transforming,
    Uploading,
    Complete,
    Failed,
}

export function useTransformationManager() {
    const [transformationStatus, setTransformationStatus] = useState<
        TransformationStatus | undefined
    >(undefined);

    const { makeTransformationQuery } = useTransformationQuery(setTransformationStatus);
    const { handleModifiedObjects, handleDeletedObjects, handleCreatedObjects } =
        useResponseHandlers();
    const { addTransformationHistoryEntry } = useTransformationHistoryManager();
    const { uploadProject } = useProjectUploader(setTransformationStatus);

    async function executeTransformation(
        transformationAction: GtmMeshTransformationAction,
        objects: ObjectIdWithVersion[],
        params: GtmMeshTransformationParams,
        options?: {
            createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void;
            handleAdditionalSideEffects?: (
                transformationResponse: GtmMeshTransformationResponse,
            ) => void;
        },
    ) {
        return makeTransformationQuery(transformationAction, objects, params)
            .then(handleModifiedObjects)
            .then(handleDeletedObjects)
            .then(handleCreatedObjects(options?.createdObjectsHandler))
            .then(options?.handleAdditionalSideEffects)
            .then(addTransformationHistoryEntry(transformationAction, params))
            .then(uploadProject)
            .then(() => setTransformationStatus(TransformationStatus.Complete))
            .catch(() => {
                setTransformationStatus(TransformationStatus.Failed);
                return Promise.reject();
            })
            .finally(() => setTransformationStatus(undefined));
    }

    return { executeTransformation, transformationStatus };
}

function useTransformationQuery(setTransformationStatus: (status: TransformationStatus) => void) {
    const gooseContext = useGooseContext();
    const [GtmMeshTransformationTrigger] = useLazyGtmMeshTransformationQuery();

    return {
        makeTransformationQuery: async (
            transformationAction: GtmMeshTransformationAction,
            objects: ObjectIdWithVersion[],
            params: GtmMeshTransformationParams,
        ) => {
            setTransformationStatus(TransformationStatus.Transforming);

            const { data, isError } = await GtmMeshTransformationTrigger(
                formGtmMeshTransformationBody(gooseContext!, transformationAction, objects, params),
            );

            return isError || !data ? Promise.reject() : data;
        },
    };
}

function useResponseHandlers() {
    const { findObjectAndSetVersion, findObjectAndDelete } = useObjectManager();

    return {
        handleModifiedObjects: async (transformationResponse: GtmMeshTransformationResponse) => {
            transformationResponse.modified.forEach((obj) => {
                findObjectAndSetVersion(obj.id, obj.version);
            });
            return transformationResponse;
        },
        handleDeletedObjects: async (transformationResponse: GtmMeshTransformationResponse) => {
            transformationResponse.deleted.forEach((obj) => {
                findObjectAndDelete(obj.id);
            });
            return transformationResponse;
        },
        handleCreatedObjects:
            (createdObjectsHandler?: (objects: ObjectIdWithVersion[]) => void) =>
            async (transformationResponse: GtmMeshTransformationResponse) => {
                if (createdObjectsHandler) createdObjectsHandler(transformationResponse.created);
                return transformationResponse;
            },
    };
}

function useTransformationHistoryManager() {
    const { addSummarizedHistoryEntry } = useHistoryManger();

    return {
        addTransformationHistoryEntry:
            (
                transformationAction: GtmMeshTransformationAction,
                transformationParams: GtmMeshTransformationParams,
            ) =>
            async () =>
                addSummarizedHistoryEntry(transformationAction, transformationParams),
    };
}

function useProjectUploader(setTransformationStatus: (status: TransformationStatus) => void) {
    const { syncProject } = useProjectSynchronizer();

    return {
        uploadProject: async () => {
            setTransformationStatus(TransformationStatus.Uploading);
            return syncProject();
        },
    };
}
