import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { GtmEvoOutputObject, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { selectUserTriangleMeshes } from 'src/store/evo/selectors';
import { addInputObjectsToModelInCurrentProject } from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelObjects,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    addToSelectedWorkspaceObjectIds,
    clearSelectedWorkspaceObjectIds,
    removeFromSelectedWorkspaceObjectIds,
    selectSelectedWorkspaceObjectIds,
    selectWorkspaceSearchTerm,
    setSelectedWorkspaceObjectIds,
} from 'src/store/ui/projectPanel';
import { fileNameExtensionRemover } from 'src/utils';
import {
    ADD_LABEL,
    ADDED_LABEL,
    NO_OBJECTS_YET_MESSAGE,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function WorkSpaceObjectsTab() {
    const applyTrace = useTrace('workspace-objects-tab');
    const dispatch = useAppDispatch();
    const userMeshes = useAppSelector(selectUserTriangleMeshes);
    const workspaceSearchTerm = useAppSelector(selectWorkspaceSearchTerm);
    const currentModelObjects = useAppSelector(selectCurrentModelObjects);
    const addedObjectIds = useMemo(
        () => currentModelObjects.map(({ id }) => id),
        [currentModelObjects],
    );
    const selectedWorkspaceObjectIds = useAppSelector(selectSelectedWorkspaceObjectIds);
    const userMeshIds = useMemo(() => userMeshes?.map(({ id }) => id), [userMeshes]) ?? [];
    const filteredUserMeshes = useMemo(
        () =>
            userMeshes?.filter((object) =>
                workspaceSearchTerm === ''
                    ? true
                    : object.name.toLowerCase().includes(workspaceSearchTerm.toLowerCase()),
            ) ?? [],
        [userMeshes, workspaceSearchTerm],
    );
    const areAllObjectsSelected =
        filteredUserMeshes.length === selectedWorkspaceObjectIds.length + addedObjectIds.length;
    const selectedUserMeshes = useMemo(
        () => userMeshes?.filter((object) => selectedWorkspaceObjectIds.includes(object.id)) ?? [],
        [userMeshes, selectedWorkspaceObjectIds],
    );
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { loadGtmObject } = useSceneObjectDataManager();
    const { syncProject } = useProjectSynchronizer();

    if (userMeshes && userMeshes.length === 0) {
        return (
            <Stack>
                <Typography>{NO_OBJECTS_YET_MESSAGE}</Typography>
            </Stack>
        );
    }

    const handleAddSelectedObjects = () => {
        const inputObjects: GtmProjectInput[] = [];
        selectedUserMeshes.forEach((object) => {
            inputObjects.push(object);
            loadGtmObject(object.id, object.version, object.name);
        });
        dispatch(addInputObjectsToModelInCurrentProject([selectedModelIndex, inputObjects]));
        dispatch(clearSelectedWorkspaceObjectIds());
        syncProject();
    };

    const handleSelectOrDeselectAllObjects = () => {
        if (areAllObjectsSelected) {
            dispatch(setSelectedWorkspaceObjectIds([]));
        } else {
            dispatch(
                setSelectedWorkspaceObjectIds(
                    filteredUserMeshes
                        .filter(({ id }) => !addedObjectIds.includes(id))
                        .map(({ id }) => id),
                ),
            );
        }
    };

    return (
        <Box padding={(theme) => theme.spacing(1, 2, 2)}>
            <Stack
                direction="row"
                sx={(theme) => ({
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0.5),
                })}
            >
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Checkbox
                        size="small"
                        disableRipple
                        disabled={addedObjectIds.length === userMeshIds.length}
                        checked={areAllObjectsSelected}
                        indeterminate={
                            selectedWorkspaceObjectIds.length > 0 && !areAllObjectsSelected
                        }
                        onChange={handleSelectOrDeselectAllObjects}
                    />
                    <Typography variant="caption">
                        {selectedWorkspaceObjectIds.length} selected
                    </Typography>
                </Stack>
                <Button
                    automation-id={applyTrace('add-selected-objects-button')}
                    size="small"
                    onClick={handleAddSelectedObjects}
                    variant="text"
                    startIcon={<AddIcon />}
                    disabled={selectedWorkspaceObjectIds.length === 0}
                >
                    {ADD_LABEL}
                </Button>
            </Stack>
            <Box
                sx={{
                    height: '200px',
                    overflowY: 'scroll',
                    borderRadius: 0.5,
                    border: 1,
                    borderColor: 'divider',
                }}
            >
                <List dense disablePadding>
                    {filteredUserMeshes.map((object) => (
                        <WorkspaceObjectListItem key={object.id} workSpaceObject={object} />
                    ))}
                </List>
            </Box>
        </Box>
    );
}

function WorkspaceObjectListItem({ workSpaceObject }: { workSpaceObject: GtmEvoOutputObject }) {
    const applyTrace = useTrace('workspace-object-list-item');
    const dispatch = useAppDispatch();
    const { loadGtmObject } = useSceneObjectDataManager();
    const currentModel = useAppSelector(selectCurrentModel);
    const currentModelObjects = useAppSelector(selectCurrentModelObjects);
    const isAdded = useMemo(
        () => currentModelObjects.some((object) => object.id === workSpaceObject.id),
        [currentModelObjects, workSpaceObject],
    );
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();
    const selectedWorkspaceObjectIds = useAppSelector(selectSelectedWorkspaceObjectIds);
    const isSelectedToAdd = selectedWorkspaceObjectIds.includes(workSpaceObject.id);

    const createOnAddClickHandler = (object: GtmEvoOutputObject) => () => {
        loadGtmObject(object.id, object.version, object.name);
        dispatch(addInputObjectsToModelInCurrentProject([selectedModelIndex, [object]]));
        syncProject();
    };

    const handleOnSelectToAdd = () => {
        if (isSelectedToAdd) {
            dispatch(removeFromSelectedWorkspaceObjectIds(workSpaceObject.id));
        } else {
            dispatch(addToSelectedWorkspaceObjectIds(workSpaceObject.id));
        }
    };

    return (
        <ListItem disableGutters disablePadding divider>
            <ListItemButton
                selected={isSelectedToAdd}
                disabled={isAdded}
                sx={(theme) => ({ padding: theme.spacing(0, 1) })}
            >
                <Checkbox
                    automation-id={applyTrace(
                        `checkbox-${fileNameExtensionRemover(workSpaceObject.name)}`,
                    )}
                    disabled={isAdded}
                    disableRipple
                    size="small"
                    checked={isSelectedToAdd || isAdded}
                    onChange={handleOnSelectToAdd}
                />
                <ListItemText
                    disableTypography
                    primary={
                        <OverflowTooltipTypography
                            variant="caption"
                            sx={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {fileNameExtensionRemover(workSpaceObject.name)}
                        </OverflowTooltipTypography>
                    }
                />
                <Button
                    size="small"
                    onClick={createOnAddClickHandler(workSpaceObject)}
                    variant="text"
                    startIcon={isAdded ? null : <AddIcon />}
                    disabled={isAdded || !currentModel}
                >
                    {isAdded ? ADDED_LABEL : ADD_LABEL}
                </Button>
            </ListItemButton>
        </ListItem>
    );
}
