import { WDSThemeProvider } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import { CameraState, Nullable, PlotState } from '@local/webviz/dist/types/xyz';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import {
    selectCurrentParameterizedGeometry,
    selectCurrentProjectName,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { CoordinatesInput } from 'src/visualization/Common/CoordinatesInput';
import {
    Axis,
    CoordinatesDisabledState,
    CoordinatesErrorState,
    Point,
} from 'src/visualization/Common/CoordinatesInput.types';
import {
    CROSS_SECTION_TITLE,
    CROSS_SECTION_NAME_SUFFIX,
    ORIGIN_LABEL,
    DIRECTION_LABEL,
    CREATE_LABEL,
} from 'src/visualization/CrossSectionPanel/CrossSectionPanel.constants';
import { getPlaneSnapshot } from 'src/visualization/CrossSectionPanel/snapshot';

import { useStyles } from './CrossSectionPanel.styles';

const defaultOrigin: Point = { x: 0, y: 0, z: 0 };

const defaultDirection: Point = { x: 1, y: 0, z: 0 };

const defaultErrorState: CoordinatesErrorState = {
    isErrorX: false,
    isErrorY: false,
    isErrorZ: false,
};

const defaultDisabledState: CoordinatesDisabledState = {
    isXDisabled: false,
    isYDisabled: false,
    isZDisabled: false,
};

export function CrossSectionPanel() {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const { classes } = useStyles();
    const [origin, setOrigin] = useState<Point>(defaultOrigin);
    const [direction, setDirection] = useState<Point>(defaultDirection);
    const [originErrorState, setOriginErrorState] =
        useState<CoordinatesErrorState>(defaultErrorState);
    const [directionErrorState, setDirectionErrorState] =
        useState<CoordinatesErrorState>(defaultErrorState);
    const { executeTransformation } = useTransformationManager();
    const currentParameterizedGeometry = useAppSelector(selectCurrentParameterizedGeometry);
    const projectName = useAppSelector(selectCurrentProjectName);
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const [crossSectionPlaneId, setCrossSectionPlaneId] = useState<Nullable<string>>(null);

    const updateOrigin = (axis: Axis, updatedValue: number) => {
        const updatedErrorState = getUpdatedErrorState(originErrorState, axis, updatedValue);
        setOriginErrorState(updatedErrorState);
        const newOrigin = { ...origin, [axis]: updatedValue };
        setOrigin(newOrigin);

        updatePlane(newOrigin, direction);
    };

    const updateDirection = (axis: Axis, updatedValue: number) => {
        const updatedErrorState = getUpdatedErrorState(directionErrorState, axis, updatedValue);
        setDirectionErrorState(updatedErrorState);
        const newDirection = { ...direction, [axis]: updatedValue };
        setDirection(newDirection);

        updatePlane(origin, newDirection);
    };

    const getUpdatedErrorState = (
        currentErrorState: CoordinatesErrorState,
        axis: Axis,
        updatedValue: number,
    ): CoordinatesErrorState => ({
        ...currentErrorState,
        [`isError${axis.toUpperCase()}`]: !Number.isFinite(updatedValue),
    });

    const createCrossSection = async () => {
        await executeTransformation(
            GtmMeshTransformationAction.CreateCrossSection,
            [currentParameterizedGeometry!],
            {
                name: projectName + CROSS_SECTION_NAME_SUFFIX,
                sectionOrigin: origin,
                sectionDirection: direction,
            },
        );
    };

    const updatePlane = (planeOrigin: Point, planeDirection: Point) => {
        removeCurrentPlaneIfItExists();

        if (!currentParameterizedGeometry) {
            return;
        }

        const randomPlaneId = Math.floor(Math.random() * 100).toString();
        setCrossSectionPlaneId(randomPlaneId);

        const cameraState = getEntityState('camera') as CameraState;
        if (!cameraState) {
            return;
        }

        const snapshot = getPlaneSnapshot(
            randomPlaneId!,
            planeOrigin,
            planeDirection,
            // TODO: Use a more appropriate side length. We should probably use the bounding box of
            // the geometry.
            cameraState.radius,
        );

        const plotState = getEntityState('plot') as PlotState;
        if (!plotState) {
            return;
        }

        const plotViewSnapshot = {
            ...snapshot,
            plot: { views: [...plotState.views, randomPlaneId] },
        };
        if (!plotViewSnapshot) {
            return;
        }

        setStateFromSnapshot(plotViewSnapshot, {});
    };

    const removeCurrentPlaneIfItExists = () => {
        if (!crossSectionPlaneId) return;

        const existingViews = (getEntityState('plot') as PlotState).views;
        existingViews.splice(existingViews.indexOf(crossSectionPlaneId!), 1);
        setStateFromSnapshot({ plot: { views: existingViews } }, {});
        setCrossSectionPlaneId(null);
    };

    useEffect(() => {
        updatePlane(origin, direction);
    }, [currentParameterizedGeometry]);

    if (!currentParameterizedGeometry) {
        return null;
    }

    const hasInputErrors =
        Object.values(originErrorState).some((error) => error) ||
        Object.values(directionErrorState).some((error) => error);

    return (
        <Box className={classes.root}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <Typography className={classes.crossSectionTitle}>
                        {CROSS_SECTION_TITLE}
                    </Typography>
                    <Divider />
                    <Stack direction="column" className={classes.inputSection}>
                        <CoordinatesInput
                            label={ORIGIN_LABEL}
                            coordinateValues={origin}
                            onChange={updateOrigin}
                            errorState={originErrorState}
                            disabledState={defaultDisabledState}
                        />
                        <CoordinatesInput
                            label={DIRECTION_LABEL}
                            coordinateValues={direction}
                            onChange={updateDirection}
                            errorState={directionErrorState}
                            disabledState={{ ...defaultDisabledState, isZDisabled: true }}
                        />
                        <Button
                            className={classes.createButton}
                            variant="contained"
                            size="small"
                            onClick={createCrossSection}
                            disabled={hasInputErrors}
                        >
                            {CREATE_LABEL}
                        </Button>
                    </Stack>
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}
