import { GtmAnalyticalModel, isGtmAnalyticalModel } from 'src/gtmProject';

import { ProjectState } from './projectSlice.types';

export function getCurrentModel(projectState: ProjectState) {
    return projectState.current.project?.models?.[projectState.current.selectedModelIndex];
}

export function getCurrentAnalyticalModel(projectState: ProjectState) {
    const currentModel = getCurrentModel(projectState);
    if (isGtmAnalyticalModel(currentModel)) {
        return currentModel;
    }
    console.error('Current model is not an analytical model');
    return {} as GtmAnalyticalModel;
}
