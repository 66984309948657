/* eslint-disable import/no-cycle */
import { createSelector } from '@reduxjs/toolkit';

import {
    GtmEvoOutputObject,
    GtmProject,
    GtmAnalyticalModel,
    GtmModel,
    GtmProjectInput,
    GtmModelSettings,
    GtmBounds,
    AggregatableObject,
} from 'src/gtmProject';

import { RootState } from '../store';
import {
    CurrentProjectState,
    DEFAULT_CURRENT_PROJECT_STATE,
    ProjectState,
} from './projectSlice.types';
import { getCurrentAnalyticalModel } from './projectSliceUtils';

export const initialProjectState: ProjectState = {
    current: DEFAULT_CURRENT_PROJECT_STATE,
    currentProjectVersionId: '',
};

type SelectorTypeCurrentProjectState = (state: RootState) => CurrentProjectState;
type SelectorTypeString = (state: RootState) => string;
type SelectorTypeStringArray = (state: RootState) => string[];
type SelectorTypeNumber = (state: RootState) => number;
type SelectorTypeGtmProjectData = (state: RootState) => GtmProject;
type SelectorTypeGtmOutputObject = (state: RootState) => GtmEvoOutputObject | undefined;
type SelectorTypeGtmOutputObjects = (state: RootState) => GtmEvoOutputObject[];
type SelectorTypeAggregatableObjects = (state: RootState) => AggregatableObject[];
type SelectorTypeBounds = (state: RootState) => GtmBounds | undefined;
type SelectorTypeGtmAnalyticalModel = (state: RootState) => GtmAnalyticalModel | undefined;
type SelectorTypeBoolean = (state: RootState) => boolean;
type SelectorTypeGtmModels = (state: RootState) => GtmModel[];
type SelectorTypeGtmModel = (state: RootState) => GtmModel | undefined;
type SelectorTypeGtmProjectInput = (state: RootState) => GtmProjectInput | undefined;
type SelectorTypeGtmProjectInputs = (state: RootState) => GtmProjectInput[];
type SelectorTypeModelSettings = (state: RootState) => GtmModelSettings | undefined;

const projectState = (state: RootState): ProjectState => state.project ?? initialProjectState;

export const selectCurrentProject: SelectorTypeCurrentProjectState = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current,
);

export const selectCurrentProjectName: SelectorTypeString = createSelector(
    selectCurrentProject,
    (currentProject) => currentProject.project?.name ?? '',
);

export const selectCurrentProjectData: SelectorTypeGtmProjectData = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.project,
);

export const selectCurrentProjectAnalyticalModel: SelectorTypeGtmAnalyticalModel = createSelector(
    projectState,
    (projectStateRoot) => {
        const currentAnalyticalModel = getCurrentAnalyticalModel(projectStateRoot);
        if (Object.entries(currentAnalyticalModel).length === 0) {
            return undefined;
        }
        return currentAnalyticalModel;
    },
);

export const selectCurrentProjectAnalyticalModelObjects: SelectorTypeAggregatableObjects =
    createSelector(
        selectCurrentProjectAnalyticalModel,
        (currentAnalyticalModel) => currentAnalyticalModel?.objects ?? [],
    );

export const selectCurrentAggregateGeometry: SelectorTypeGtmOutputObject = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.aggregateGeometry,
);

export const selectCurrentParameterizedGeometry: SelectorTypeGtmOutputObject = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.parameterizedGeometry,
);

export const selectCurrentBounds: SelectorTypeBounds = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.bounds,
);

export const anAnalyticalModelIsSelected: SelectorTypeBoolean = createSelector(
    selectCurrentAggregateGeometry,
    (currentAggregateGeometry) => currentAggregateGeometry !== undefined,
);

export const selectCurrentProjectVersionId: SelectorTypeString = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.currentProjectVersionId,
);

export const selectCurrentProjectVolumes: SelectorTypeGtmOutputObjects = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.volumes ?? [],
);

export const selectCurrentShowVolumes: SelectorTypeBoolean = createSelector(
    selectCurrentProjectAnalyticalModel,
    (currentAnalyticalModel) => currentAnalyticalModel?.showVolumes ?? false,
);

export const selectCurrentProjectModels: SelectorTypeGtmModels = createSelector(
    selectCurrentProjectData,
    (currentProject) => currentProject.models ?? [],
);

export const selectSelectedModelIndex: SelectorTypeNumber = createSelector(
    projectState,
    (projectStateRoot) => projectStateRoot.current.selectedModelIndex,
);

export const selectCurrentModel: SelectorTypeGtmModel = createSelector(
    projectState,
    selectSelectedModelIndex,
    (projectStateRoot, selectedModelIndex) =>
        projectStateRoot.current.project?.models?.[selectedModelIndex],
);

export const selectCurrentModelObjects: SelectorTypeGtmProjectInputs = createSelector(
    selectCurrentModel,
    (currentModel) => currentModel?.inputObjects ?? [],
);

export const selectCurrentModelSettings: SelectorTypeModelSettings = createSelector(
    selectCurrentModel,
    (currentModel) => currentModel?.settings,
);

export const selectIsCurrentProjectEmpty: SelectorTypeBoolean = createSelector(
    selectCurrentProjectData,
    (currentProject) => Object.entries(currentProject).length === 0,
);

export const selectCurrentProjectModelNames: SelectorTypeStringArray = createSelector(
    selectCurrentProjectData,
    (currentProject) => {
        const names: string[] = [];
        currentProject?.models?.forEach((model) => {
            if (model.name) {
                names.push(model.name);
            }
        });
        return names;
    },
);

export const selectCurrentModelSelectedObjectIndex: SelectorTypeNumber = createSelector(
    selectCurrentProject,
    (currentProjectState) => currentProjectState.currentModelSelectedObjectIndex,
);

export const selectCurrentModelSelectedObject: SelectorTypeGtmProjectInput = createSelector(
    selectCurrentModelObjects,
    selectCurrentModelSelectedObjectIndex,
    (currentModelObjects, currentModelSelectedObjectIndex) =>
        currentModelObjects[currentModelSelectedObjectIndex],
);
