import ViewCarouselSharp from '@mui/icons-material/ViewCarouselSharp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useTransformationManager } from 'src/hooks/transformation/useTransformationManager';
import { DeprecatedTransformationProgressModal } from 'src/visualization/TransformationProgressModal/DeprecatedTransformationProgressModal';

import { useStyles } from './ObjectListItemControl.styles';
import { START_REMESH_MESSAGE } from './RemeshControl.constants';

const DEFAULT_REMESH_PARAMS = {
    patchAngleTolerance: 10.0, // Angle change in degrees to define a patch skeleton line
    maxChordalError: -0.005, // max relative local error (-0.005 = 0.5%)
    strainTolerance: 0.3, // relative quality difference before and after unfolding to plane
    isClosed: false, // should be true for manifold GMVs
    initialCleanup: true, // e.g. node merging, gaps filling, topological fixing
    lineProximityDetection: false, // Consider distance between skeleton lines for element sizes
    optimize: true, // perform final optimization
    shapeQualityWeight: 0.6, // size vs shape weight (0 to 1)
    targetH: 0, // target element size (0 is based on skeleton lines)
};

export function RemeshControl({ inputMesh }: { inputMesh: GtmEvoOutputObject }) {
    const { classes } = useStyles();
    const { executeTransformation, transformationStatus } = useTransformationManager();

    async function performRemesh() {
        await executeTransformation(GtmMeshTransformationAction.Remesh, [inputMesh], {
            patchAngleTolerance: DEFAULT_REMESH_PARAMS.patchAngleTolerance,
            maxChordalError: DEFAULT_REMESH_PARAMS.maxChordalError,
            strainTolerance: DEFAULT_REMESH_PARAMS.strainTolerance,
            isClosed: DEFAULT_REMESH_PARAMS.isClosed,
            initialCleanup: DEFAULT_REMESH_PARAMS.initialCleanup,
            lineProximityDetection: DEFAULT_REMESH_PARAMS.lineProximityDetection,
            optimize: DEFAULT_REMESH_PARAMS.optimize,
            shapeQualityWeight: DEFAULT_REMESH_PARAMS.shapeQualityWeight,
            targetH: DEFAULT_REMESH_PARAMS.targetH,
        });
    }

    return (
        <>
            <Tooltip title="Remesh" placement="top" arrow enterDelay={0}>
                {/* TODO: this is a temporary icon */}
                <IconButton
                    onClick={performRemesh}
                    className={classnames(classes.removeIconButton)}
                >
                    <ViewCarouselSharp fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <DeprecatedTransformationProgressModal
                transformationStatus={transformationStatus}
                transformationMessage={START_REMESH_MESSAGE}
            />
        </>
    );
}
