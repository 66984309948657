import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { selectCurrentModel, selectCurrentProjectModels } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    ObjectsTabSelection,
    selectIsSearchingWorkspaceObjects,
    selectObjectsPanelTabSelection,
    setIsSearchingWorkspaceObjects,
    setObjectsPanelTabSelection,
} from 'src/store/ui/projectPanel';
import { ModelObjectsTab } from 'src/visualization/ProjectPanel/components/ObjectsPanel/ModelsObjectTab';
import { UploadObjectsDialog } from 'src/visualization/ProjectPanel/components/ObjectsPanel/UploadObjectsDialog/UploadObjectsDialog';
import { WorkSpaceObjectsTab } from 'src/visualization/ProjectPanel/components/ObjectsPanel/WorkspaceObjectsTab';
import { WorkspaceSearchInput } from 'src/visualization/ProjectPanel/components/ObjectsPanel/WorkspaceSearchInput';
import {
    MODEL_LABEL,
    OBJECTS_LABEL,
    WORKSPACE_LABEL,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function ObjectsPanel() {
    const applyTrace = useTrace('objects-panel');
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const tabSelection = useAppSelector(selectObjectsPanelTabSelection);
    const isSearchingWorkspaceObjects = useAppSelector(selectIsSearchingWorkspaceObjects);
    const [openUploadObjectsDialog, setOpenUploadObjectsDialog] = useState(false);
    const models = useAppSelector(selectCurrentProjectModels);
    const currentModel = useAppSelector(selectCurrentModel);

    const handleTabSelection = (_e: unknown, newValue: ObjectsTabSelection) => {
        dispatch(setObjectsPanelTabSelection(newValue));
    };

    const handleSearchWorkspaceClick = () => {
        dispatch(setIsSearchingWorkspaceObjects(true));
        dispatch(setObjectsPanelTabSelection(ObjectsTabSelection.WorkSpaceObjects));
    };

    const handleOpenUploadObjectsDialog = () => {
        setOpenUploadObjectsDialog(true);
    };

    const handleCloseUploadObjectsDialog = () => {
        setOpenUploadObjectsDialog(false);
    };

    return (
        <Stack automation-id={applyTrace()}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: theme.spacing(1.5, 2, 1, 2),
                    alignItems: 'center',
                }}
            >
                <Typography variant="overline" color="secondary">
                    {OBJECTS_LABEL}
                </Typography>
                <IconButton
                    sx={{ padding: 0 }}
                    disabled={models.length === 0 || !currentModel}
                    automation-id={applyTrace('upload-button')}
                    size="small"
                    color="primary"
                    onClick={handleOpenUploadObjectsDialog}
                >
                    <UploadIcon />
                </IconButton>
            </Box>
            {isSearchingWorkspaceObjects ? (
                <WorkspaceSearchInput />
            ) : (
                <Stack
                    sx={{
                        borderBottom: `${theme.palette.divider} 1px solid`,
                        justifyContent: 'space-between',
                    }}
                    direction="row"
                >
                    <Tabs value={tabSelection} onChange={handleTabSelection}>
                        <Tab
                            automation-id={applyTrace('model-objects-tab')}
                            label={
                                <Typography variant="button" sx={{ textTransform: 'capitalize' }}>
                                    {MODEL_LABEL}
                                </Typography>
                            }
                            value={ObjectsTabSelection.ModelObjects}
                        />
                        <Tab
                            automation-id={applyTrace('workspace-objects-tab')}
                            label={
                                <Typography variant="button" sx={{ textTransform: 'capitalize' }}>
                                    {WORKSPACE_LABEL}
                                </Typography>
                            }
                            value={ObjectsTabSelection.WorkSpaceObjects}
                        />
                    </Tabs>
                    <IconButton
                        automation-id={applyTrace('workspace-search-button')}
                        disableRipple
                        sx={{ marginRight: theme.spacing(2), padding: 0 }}
                        onClick={handleSearchWorkspaceClick}
                    >
                        <SearchIcon />
                    </IconButton>
                </Stack>
            )}
            {tabSelection === ObjectsTabSelection.ModelObjects ? (
                <ModelObjectsTab />
            ) : (
                <WorkSpaceObjectsTab />
            )}
            <UploadObjectsDialog
                shouldOpen={openUploadObjectsDialog}
                closeDialogCallback={handleCloseUploadObjectsDialog}
            />
        </Stack>
    );
}
