import { CollapsibleContainer } from '@local/web-design-system/dist/components/CollapsibleContainer';
import {
    IndicatorValuesIcon,
    TerminatedVeinIcon,
    FaultIcon,
    MeshIcon,
    FolderIcon,
    EdgesToggleIcon,
} from '@local/web-design-system/dist/icons';
import { CalculatedNumericValuesIcon } from '@local/web-design-system/dist/icons/Artifacts/CalculatedNumericValuesIcon';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { useState, useEffect, useMemo } from 'react';

import {
    GtmMeshDetectionData,
    GtmMeshDetectorAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useSceneObjectDataManager } from 'src/hooks';
import { useDefectsVisualizationManager } from 'src/hooks/defects';
import { IssuesMap, IssueInfo } from 'src/store/issues/issuesSlice.types';
import { anyIssueIsStillLoading, issuesMapForObject } from 'src/store/issues/selectors';
import { anAnalyticalModelIsSelected } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { firstSelectedSceneObjectId } from 'src/store/visualization/selectors';
import { countIssues, countAllIssues } from 'src/utils/countIssues';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';
import { SkeletonArtifactsPanelContents } from 'src/visualization/Visualization/ArtifactsPanel/ProjectTree/ProjectTreePanel';

import {
    ArtifactAccordion,
    ArtifactAccordionItemProps,
    ArtifactVirtualizedList,
} from '../../ArtifactAccordion/ArtifactAccordion';
import { ArtifactListItemControl } from '../../ArtifactListItemControl/ArtifactListItemControl';
import { FillHolesDialog } from '../../TransformationDialogs/FillHoles';
import { FILL_HOLES } from '../../TransformationDialogs/FillHoles.constants';
import { RemoveDegenerateTrianglesDialog } from '../../TransformationDialogs/RemoveDegenerateTriangles';
import { REMOVE_DEGENERATE_TRIANGLES } from '../../TransformationDialogs/RemoveDegenerateTriangles.constants';
import { EMPTY_STATE_MESSAGE, NO_ISSUES_MESSAGE } from './DefectsDialog.constants';
import { useStyles } from './DefectsDialog.styles';

function getIconFromDetectorAction(action: GtmMeshDetectorAction) {
    switch (action) {
        case GtmMeshDetectorAction.DetectDegenerateTris:
        case GtmMeshDetectorAction.DetectDuplicateTris:
        case GtmMeshDetectorAction.DetectSelfIntersections:
        case GtmMeshDetectorAction.DetectFins:
        case GtmMeshDetectorAction.DetectInconsistentlyOrientedTris:
            return <FaultIcon />;
        case GtmMeshDetectorAction.DetectDuplicatePoints:
        case GtmMeshDetectorAction.DetectHoles:
        case GtmMeshDetectorAction.DetectNonManifoldEdges:
        case GtmMeshDetectorAction.DetectNonManifoldVertices:
            return <CalculatedNumericValuesIcon />;
        case GtmMeshDetectorAction.DetectNonPartitioningSurfaces:
            return <TerminatedVeinIcon />;
        default:
            return <FolderIcon />;
    }
}

function createPointItems(
    idBase: string,
    defect: GtmMeshDetectionData[number],
    action: GtmMeshDetectorAction,
): ArtifactAccordionItemProps[] {
    return defect.points.map((pointId: number) => ({
        id: `${idBase}-point-${pointId}`,
        LeafElementType: ArtifactListItemControl,
        leafElementProps: {
            artifactName: `Point ${pointId}`,
            artifactIndex: pointId,
            action,
        },
    }));
}

function createEdgeItems(
    idBase: string,
    defect: GtmMeshDetectionData[number],
    action: GtmMeshDetectorAction,
): ArtifactAccordionItemProps[] {
    return defect.edges.map((edge: [number, number], edgeIndex: number) => ({
        id: `${idBase}-edge-${edgeIndex}`,
        LeafElementType: ArtifactListItemControl,
        leafElementProps: {
            artifactName: `Edge ${edgeIndex}`,
            artifactIndex: edgeIndex,
            action,
        },
    }));
}

function createTriangleItems(
    idBase: string,
    defect: GtmMeshDetectionData[number],
    action: GtmMeshDetectorAction,
): ArtifactAccordionItemProps[] {
    return defect.triangles.map((triangleId: number) => ({
        id: `${idBase}-triangle-${triangleId}`,
        LeafElementType: ArtifactListItemControl,
        leafElementProps: {
            artifactName: `Triangle ${triangleId}`,
            artifactIndex: triangleId,
            action,
        },
    }));
}

function createHoleItems(
    idBase: string,
    holeDefects: GtmMeshDetectionData,
    action: GtmMeshDetectorAction,
): ArtifactAccordionItemProps[] {
    const icon = <TerminatedVeinIcon />;
    return holeDefects.map((_hole, holeIndex) => ({
        id: `${idBase}-hole-${holeIndex}`,
        icon,
        LeafElementType: ArtifactListItemControl,
        leafElementProps: {
            artifactName: `Hole ${holeIndex}`,
            artifactIndex: holeIndex,
            action,
        },
    }));
}

function createNonPartitioningSurfaceItems(
    idBase: string,
    nonPartSurfDefects: GtmMeshDetectionData,
    action: GtmMeshDetectorAction,
): ArtifactAccordionItemProps[] {
    const icon = <TerminatedVeinIcon />;
    return nonPartSurfDefects.map((_, index) => ({
        id: `${idBase}-NonPartitioningSurface-${index}`,
        icon,
        LeafElementType: ArtifactListItemControl,
        leafElementProps: {
            artifactName: `Non-partitioning surface ${index}`,
            artifactIndex: index,
            action,
        },
    }));
}

interface DefectAccordionProps {
    idBase: string;
    action: GtmMeshDetectorAction;
    data: GtmMeshDetectionData;
}

// AccordionForHoles is a bit of a misnomer since we purposely keep it as a flat list.
// ie: we want:
// Holes (6) [icon]
//      Hole 1
//      Hole 2 ...
// instead of:
// Holes (6) [icon]
//   [anotherIcon] Holes (6)
//      Hole 1
//      Hole 2 ...
const AccordionForHoles = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (data?.length === 0) {
        return null;
    }
    return (
        <ArtifactVirtualizedList
            key={`${accordionIdBase}-holes`}
            items={createHoleItems(`${accordionIdBase}-holes`, data, action)}
        />
    );
};

const AccordionForNonPartitioningSurfaces = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (data?.length === 0) {
        return null;
    }
    return (
        <ArtifactVirtualizedList
            key={`${accordionIdBase}-nonpartsurfs`}
            items={createNonPartitioningSurfaceItems(
                `${accordionIdBase}-nonpartsurfs`,
                data,
                action,
            )}
        />
    );
};

const AccordionForGeneralDefects = ({ idBase, action, data }: DefectAccordionProps) => {
    const accordionIdBase = `${idBase}-Accordion`;
    if (data?.length === 0) {
        return null;
    }

    if (data.length === 1) {
        const defects = data[0];
        return (
            <>
                {defects.points?.length > 0 && (
                    <ArtifactAccordion
                        key={`${accordionIdBase}-points`}
                        rootElementTitle={`Points (${data[0].points.length})`}
                        rootElementIcon={<IndicatorValuesIcon />}
                        rootElementItems={createPointItems(
                            `${accordionIdBase}-points`,
                            defects,
                            action,
                        )}
                    />
                )}
                {defects.edges?.length > 0 && (
                    <ArtifactAccordion
                        key={`${accordionIdBase}-edges`}
                        rootElementTitle={`Edges (${data[0].edges.length})`}
                        rootElementIcon={<MeshIcon />}
                        rootElementItems={createEdgeItems(
                            `${accordionIdBase}-edges`,
                            defects,
                            action,
                        )}
                    />
                )}
                {defects.triangles?.length > 0 && (
                    <ArtifactAccordion
                        key={`${accordionIdBase}-triangles`}
                        rootElementTitle={`Triangles (${data[0].triangles.length})`}
                        rootElementIcon={<EdgesToggleIcon />}
                        rootElementItems={createTriangleItems(
                            `${accordionIdBase}-triangles`,
                            defects,
                            action,
                        )}
                    />
                )}
            </>
        );
    }
    // Note: Setting the key with the index here can potentially cause issues:
    //       https://robinpokorny.com/blog/index-as-a-key-is-an-anti-pattern/
    //       Ideally, we can identify "root groups" by a unique identifier in the future.
    //       (I.e. we add IDs to grouped data (such as an ID per hole))
    //       If not, then we can come up with a unique-id generator to avoid this issue.
    return data.map((defectGroup: GtmMeshDetectionData[number], index) => {
        const defectGroupId = `${accordionIdBase}-${index}`;
        return (
            <ArtifactAccordion
                key={defectGroupId}
                rootElementIcon={getIconFromDetectorAction(action)}
                rootElementTitle={`${detectorActionToDefectString(action)} ${index}`}
                rootElementItems={[
                    ...(defectGroup.points?.length
                        ? [
                              {
                                  title: `Points (${defectGroup.points.length})`,
                                  icon: <IndicatorValuesIcon />,
                                  id: `${defectGroupId}-points`,
                                  items: createPointItems(
                                      `${defectGroupId}-points`,
                                      defectGroup,
                                      action,
                                  ),
                              },
                          ]
                        : []),
                    ...(defectGroup.edges?.length
                        ? [
                              {
                                  title: `Edges (${defectGroup.edges.length})`,
                                  icon: <MeshIcon />,
                                  id: `${defectGroupId}-edges`,
                                  items: createEdgeItems(
                                      `${defectGroupId}-edges`,
                                      defectGroup,
                                      action,
                                  ),
                              },
                          ]
                        : []),
                    ...(defectGroup.triangles?.length
                        ? [
                              {
                                  title: `Triangles (${defectGroup.triangles.length})`,
                                  id: `${defectGroupId}-triangles`,
                                  icon: <EdgesToggleIcon />,
                                  items: createTriangleItems(
                                      `${defectGroupId}-triangles`,
                                      defectGroup,
                                      action,
                                  ),
                              },
                          ]
                        : []),
                ]}
            />
        );
    });
};

const AccordionForDefects = (props: DefectAccordionProps) => {
    switch (props.action) {
        case GtmMeshDetectorAction.DetectHoles:
            return AccordionForHoles(props);
        case GtmMeshDetectorAction.DetectNonPartitioningSurfaces:
            return AccordionForNonPartitioningSurfaces(props);
        default:
            return AccordionForGeneralDefects(props);
    }
};

const TransformationButton = ({
    buttonText,
    DialogElement,
}: {
    buttonText: string;
    DialogElement: any;
}) => {
    const isEnabled = useAppSelector(anAnalyticalModelIsSelected);
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

    return (
        <>
            <Button
                onClick={() => {
                    setIsDialogVisible(!isDialogVisible);
                }}
                disabled={!isEnabled}
            >
                {buttonText}
            </Button>
            <DialogElement
                open={isDialogVisible}
                onClose={() => {
                    setIsDialogVisible(false);
                }}
            />
        </>
    );
};

const ActionButtonsForDefects = ({ action }: { action: GtmMeshDetectorAction }) => {
    switch (action) {
        case GtmMeshDetectorAction.DetectDegenerateTris:
            return (
                <TransformationButton
                    buttonText={REMOVE_DEGENERATE_TRIANGLES}
                    DialogElement={RemoveDegenerateTrianglesDialog}
                />
            );
        case GtmMeshDetectorAction.DetectHoles:
            return <TransformationButton buttonText={FILL_HOLES} DialogElement={FillHolesDialog} />;
        default:
            return null;
    }
};

const IssueRenderer = ({
    objectId,
    action,
    info,
}: {
    objectId: string;
    action: GtmMeshDetectorAction;
    info: IssueInfo;
}) => {
    const { refreshIssues, removeIssuesForObject } = useDefectsVisualizationManager();

    useEffect(() => {
        if (!info.isLoading && !info.isError) {
            if (info.data) refreshIssues(objectId, action, info.data);
            else removeIssuesForObject(objectId, action);
        }
        return () => {
            removeIssuesForObject(objectId, action);
        };
    }, [info]);

    return null;
};

const IssueTree = ({ objectId, issueMap }: { objectId: string; issueMap: IssuesMap }) => {
    const { classes } = useStyles();

    return (
        <Grid container alignItems="left" justifyContent="left">
            {issueMap &&
                Object.entries(issueMap).map(([issueKey, issueInfo]: [string, IssueInfo]) => {
                    const action = issueKey as GtmMeshDetectorAction;

                    const actionDisplayString = detectorActionToDefectString(action);
                    const idString = `${actionDisplayString}-list`;
                    return (
                        issueInfo.data &&
                        issueInfo.data.length > 0 && (
                            <CollapsibleContainer
                                title={`${actionDisplayString}s`}
                                icon={
                                    <Grid
                                        container
                                        flexWrap="nowrap"
                                        className={classes.defectIcon}
                                    >
                                        {getIconFromDetectorAction(action)}
                                        <Typography className={classes.defectsCount}>
                                            {countIssues(action, issueInfo.data)}
                                        </Typography>
                                    </Grid>
                                }
                                key={idString}
                            >
                                <Grid className={classes.accordionDefectsContainer}>
                                    <AccordionForDefects
                                        idBase={idString}
                                        action={action}
                                        data={issueInfo.data}
                                    />
                                </Grid>
                                <ActionButtonsForDefects action={action} />
                                <IssueRenderer
                                    objectId={objectId}
                                    action={action}
                                    info={issueInfo}
                                />
                            </CollapsibleContainer>
                        )
                    );
                })}
        </Grid>
    );
};

const DefectDialogMessage = ({ text }: { text: string }) => {
    const { classes } = useStyles();

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.emptyStateMessageContainer}
        >
            <Typography align="center" className={classes.emptyStateMessage}>
                {text}
            </Typography>
        </Grid>
    );
};

export function DefectsDialog() {
    const { classes } = useStyles();
    const { isObjectOnPlotByObjectId } = useSceneObjectDataManager();

    const selectedObjectId = useAppSelector(firstSelectedSceneObjectId);
    const anyIssueIsLoading =
        useAppSelector(anyIssueIsStillLoading(selectedObjectId ?? '')) && selectedObjectId;
    const issuesMap = useAppSelector(issuesMapForObject(selectedObjectId ?? ''));
    const isObjectOnPlot = isObjectOnPlotByObjectId(selectedObjectId ?? '') && selectedObjectId;

    return useMemo(
        () => (
            <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                width="100%"
                className={classes.root}
            >
                {selectedObjectId && isObjectOnPlot && issuesMap && (
                    <IssueTree objectId={selectedObjectId} issueMap={issuesMap} />
                )}
                {(!selectedObjectId || !isObjectOnPlot) && (
                    <DefectDialogMessage text={EMPTY_STATE_MESSAGE} />
                )}
                {anyIssueIsLoading && <SkeletonArtifactsPanelContents />}
                {!anyIssueIsLoading && issuesMap && countAllIssues(issuesMap) === 0 && (
                    <DefectDialogMessage text={NO_ISSUES_MESSAGE} />
                )}
            </Grid>
        ),
        [selectedObjectId, anyIssueIsLoading, issuesMap, isObjectOnPlot],
    );
}
