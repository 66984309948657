import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            width: '275px',
            position: 'absolute',
            right: '16px',
            top: '500px',
            zIndex: 2,
        },
        crossSectionTitle: {
            padding: theme.spacing(2),
        },
        inputSection: {
            paddingBottom: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
        createButton: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
