/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash-es/cloneDeep';

import {
    GtmEvoOutputObject,
    GtmProject,
    GtmHistoryEntry,
    GtmModel,
    GtmProjectInput,
    AggregatableObject,
} from 'src/gtmProject';
import { VersionId } from 'src/types/core.types';

import {
    CurrentProjectState,
    DEFAULT_CURRENT_PROJECT_STATE,
    ProjectState,
} from './projectSlice.types';
import { getCurrentAnalyticalModel } from './projectSliceUtils';
// eslint-disable-next-line import/no-cycle
import { initialProjectState } from './selectors';

export const projectSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        setCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: payload.project ?? ({} as GtmProject),
                },
            };
        },
        overwriteProject(
            projectState: ProjectState,
            { payload }: PayloadAction<Partial<CurrentProjectState>>,
        ) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: cloneDeep(payload.project) as GtmProject,
                },
            };
        },
        clearCurrentProject(projectState: ProjectState) {
            projectState.current = DEFAULT_CURRENT_PROJECT_STATE;
        },
        clearProject(_: ProjectState) {
            return initialProjectState;
        },
        setProjectName(projectState: ProjectState, { payload }: PayloadAction<string>) {
            return {
                ...projectState,
                current: {
                    ...projectState.current,
                    project: { ...projectState.current.project, name: payload },
                },
            };
        },
        setCurrentProjectVersionId(projectState: ProjectState, { payload }: PayloadAction<string>) {
            projectState.currentProjectVersionId = payload;
        },
        setVolumes(projectState: ProjectState, { payload }: PayloadAction<GtmEvoOutputObject[]>) {
            getCurrentAnalyticalModel(projectState).volumes = payload;
        },
        clearVolumes(projectState: ProjectState) {
            getCurrentAnalyticalModel(projectState).volumes = [];
        },
        setShowVolumes(
            projectState: ProjectState,
            { payload: showVolumes }: PayloadAction<boolean>,
        ) {
            getCurrentAnalyticalModel(projectState).showVolumes = showVolumes;
        },
        setIsAggregated(
            projectState: ProjectState,
            { payload: [objectIndex, isAggregated] }: PayloadAction<[number, boolean]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].isAggregated =
                isAggregated;
        },
        setObjectVersion(
            projectState: ProjectState,
            { payload: [objectIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).objects[objectIndex].version = version;
        },
        setVolumeVersion(
            projectState: ProjectState,
            { payload: [volumeIndex, version] }: PayloadAction<[number, VersionId]>,
        ) {
            getCurrentAnalyticalModel(projectState).volumes![volumeIndex].version = version;
        },
        setAggregateVersion(projectState: ProjectState, { payload }: PayloadAction<VersionId>) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry!.version = payload;
        },
        removeObject(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).objects = getCurrentAnalyticalModel(
                projectState,
            ).objects.filter((obj) => obj.id !== objectId);
        },
        removeVolume(projectState: ProjectState, { payload: objectId }: PayloadAction<string>) {
            getCurrentAnalyticalModel(projectState).volumes = getCurrentAnalyticalModel(
                projectState,
            ).volumes?.filter((vol) => vol.id !== objectId);
        },
        removeAggregate(projectState: ProjectState) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry = undefined;
        },
        addObject(
            projectState: ProjectState,
            { payload: object }: PayloadAction<AggregatableObject>,
        ) {
            getCurrentAnalyticalModel(projectState).objects.push(object);
        },
        addVolume(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            if (getCurrentAnalyticalModel(projectState).volumes) {
                getCurrentAnalyticalModel(projectState).volumes!.push(object);
            } else {
                getCurrentAnalyticalModel(projectState).volumes = [object];
            }
        },
        setParameterizedGeometry(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject>,
        ) {
            getCurrentAnalyticalModel(projectState).parameterizedGeometry = object;
        },
        setAggregate(
            projectState: ProjectState,
            { payload: object }: PayloadAction<GtmEvoOutputObject | undefined>,
        ) {
            getCurrentAnalyticalModel(projectState).aggregateGeometry = object;
        },
        appendHistoryEntry(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmHistoryEntry>,
        ) {
            projectState.current.project.history.push(payload);
        },
        setSelectedModelIndex(projectState: ProjectState, { payload }: PayloadAction<number>) {
            projectState.current.selectedModelIndex = payload;
        },
        setCurrentModelSelectedObjectIndex(
            projectState: ProjectState,
            { payload }: PayloadAction<number>,
        ) {
            projectState.current.currentModelSelectedObjectIndex = payload;
        },
        deselectCurrentModelSelectedObject(projectState: ProjectState) {
            projectState.current.currentModelSelectedObjectIndex = -1;
        },
        updateModelAtIndexForCurrentProject(
            projectState: ProjectState,
            { payload: [newEntries, index] }: PayloadAction<[Partial<GtmModel>, number]>,
        ) {
            projectState.current.project.models[index] = {
                ...projectState.current.project.models[index],
                ...newEntries,
            };
        },
        deleteModelInCurrentProject(
            projectState: ProjectState,
            { payload }: PayloadAction<GtmModel>,
        ) {
            projectState.current.project.models = projectState.current.project.models.filter(
                (model) => model.id !== payload.id,
            );
        },
        addModelToCurrentProject(projectState: ProjectState, { payload }: PayloadAction<GtmModel>) {
            projectState.current.project.models.push(payload);
        },
        addInputObjectsToModelInCurrentProject(
            projectState: ProjectState,
            { payload: [modelIndex, inputObjects] }: PayloadAction<[number, GtmProjectInput[]]>,
        ) {
            projectState.current.project.models[modelIndex].inputObjects?.push(...inputObjects);
        },
        removeInputObjectFromModelInCurrentProject(
            projectState: ProjectState,
            { payload: [modelIndex, inputObject] }: PayloadAction<[number, GtmProjectInput]>,
        ) {
            projectState.current.project.models[modelIndex].inputObjects =
                projectState.current.project.models[modelIndex].inputObjects?.filter(
                    (obj) => obj.id !== inputObject.id,
                );
        },
    },
});

export const {
    setCurrentProject,
    overwriteProject,
    clearCurrentProject,
    clearProject,
    setProjectName,
    setCurrentProjectVersionId,
    setVolumes,
    clearVolumes,
    setShowVolumes,
    setIsAggregated,
    setSelectedModelIndex,
    setCurrentModelSelectedObjectIndex,
    deselectCurrentModelSelectedObject,
    appendHistoryEntry,
    setObjectVersion,
    setVolumeVersion,
    setAggregateVersion,
    removeObject,
    addVolume,
    setParameterizedGeometry,
    setAggregate,
    addObject,
    removeAggregate,
    removeVolume,
    updateModelAtIndexForCurrentProject,
    deleteModelInCurrentProject,
    addModelToCurrentProject,
    addInputObjectsToModelInCurrentProject,
    removeInputObjectFromModelInCurrentProject,
} = projectSlice.actions;
