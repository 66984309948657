import {
    formGtmMeshDetectorBody,
    useLazyGtmMeshDetectorQuery,
} from 'src/apiClients/gtmCompute/gtmComputeApi';
import {
    GtmMeshDetectorAction,
    GtmMeshDetectorParams,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { useIssueManager } from 'src/hooks/issues/useIssueManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useGooseContext } from 'src/hooks/useGooseContext';
import { detectorSettingsMap } from 'src/store/issues/selectors';
import { GtmObjectType } from 'src/store/project/projectSlice.types';
import { useAppSelector } from 'src/store/store';
import { ObjectIdWithVersion } from 'src/types/core.types';

export function useDefectsLoadingManager() {
    const { runDetector } = useSingleDetectorRunner();
    const detectorSettings = useAppSelector(detectorSettingsMap);

    async function runAllDetectors(versionedObject: ObjectIdWithVersion) {
        await Promise.all(
            Object.values(GtmMeshDetectorAction)
                .filter((action) => action !== GtmMeshDetectorAction.DetectFins)
                .map((action) => runDetector(versionedObject, action, detectorSettings[action])),
        );
    }
    return { runAllDetectors };
}

function useSingleDetectorRunner() {
    const { objectType } = useObjectManager();
    const { setError, setIssueData } = useIssueManager();
    const { makeDetectionQuery } = useDetectionQuery();

    function detectorIsApplicable(
        action: GtmMeshDetectorAction,
        versionedObject: ObjectIdWithVersion,
    ) {
        // We implemented these detectors but they currently serve no purpose so are disabled
        if (
            action === GtmMeshDetectorAction.DetectNonManifoldEdges ||
            action === GtmMeshDetectorAction.DetectNonManifoldVertices
        )
            return false;

        switch (objectType(versionedObject.id)) {
            case GtmObjectType.AggregateGeometry:
                return true;

            default:
                return action !== GtmMeshDetectorAction.DetectNonPartitioningSurfaces;
        }
    }

    return {
        runDetector: async (
            versionedObject: ObjectIdWithVersion,
            action: GtmMeshDetectorAction,
            params: GtmMeshDetectorParams,
        ) => {
            if (detectorIsApplicable(action, versionedObject)) {
                setError(versionedObject.id, action, false);

                await makeDetectionQuery(action, versionedObject, params)
                    .then((data) => setIssueData(versionedObject.id, action, data))
                    .catch(() => setError(versionedObject.id, action, true));
            }
        },
    };
}

function useDetectionQuery() {
    const gooseContext = useGooseContext();
    const [GtmMeshDetectionTrigger] = useLazyGtmMeshDetectorQuery();
    const { setLoading } = useIssueManager();

    return {
        makeDetectionQuery: async (
            detectionAction: GtmMeshDetectorAction,
            versionedObject: ObjectIdWithVersion,
            params: GtmMeshDetectorParams,
        ) => {
            setLoading(versionedObject.id, detectionAction, true);
            const { data, isError } = await GtmMeshDetectionTrigger(
                formGtmMeshDetectorBody(gooseContext!, detectionAction, [versionedObject], params),
            );
            setLoading(versionedObject.id, detectionAction, false);

            return isError || !data ? Promise.reject() : data;
        },
    };
}
