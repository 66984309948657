import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import debounce from 'lodash-es/debounce';
import { ChangeEvent, useContext } from 'react';

import { DEFAULT_MODEL_SETTINGS, METRES_UNIT_VALUE, PROJECT_DISTANCE_UNITS } from 'src/constants';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { GtmModel, GtmModelSettings } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { updateModelAtIndexForCurrentProject } from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelSettings,
    selectSelectedModelIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';

import {
    BACKGROUND_LABEL,
    MODEL_TITLE,
    OFF_LABEL,
    ON_LABEL,
    UNITS_LABEL,
    XYZ_AXIS_LABEL,
} from './ModelSettingsPanel.constants';
import { useStyles } from './ModelSettingsPanel.styles';

export function ModelSettingsPanel() {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const { classes } = useStyles();

    return (
        <Box className={classes.root}>
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <Typography className={classes.modelTitle}>{MODEL_TITLE}</Typography>
                    <Divider />
                    <SettingsSection />
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
}

function SettingsSection() {
    const { classes } = useStyles();
    return (
        <Stack direction="column" className={classes.settingsSection}>
            <BackgroundColorInput />
            <DistanceUnitsInput />
            <XYZAxisToggle />
        </Stack>
    );
}

function getUpdatedSettings(model: GtmModel, newSettings: Partial<GtmModelSettings>) {
    return {
        ...(model.settings ?? DEFAULT_MODEL_SETTINGS),
        ...newSettings,
    };
}

function BackgroundColorInput() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, {
            backgroundColor: event.target.value.toUpperCase(),
        });

        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    // TODO: Debouncing for now to prevent calling the API too many times
    // TODO: Remove after introducing a custom component. A save button should trigger the API
    const debouncedHandleChange = debounce(handleChange, 500);

    // TODO: Use a custom color picker component instead of the `<input type="color" />`
    // TODO (GEOM-547): Change background based on the color picked
    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {BACKGROUND_LABEL}
            </Typography>
            <IconButton component="label">
                <input type="color" style={{ display: 'none' }} onChange={debouncedHandleChange} />
                <Icon
                    sx={{
                        width: '18px',
                        height: '18px',
                        borderRadius: '4px',
                        backgroundColor:
                            currentModelSettings?.backgroundColor ??
                            DEFAULT_MODEL_SETTINGS.backgroundColor,
                        border: '1px solid #FAFCFF8F',
                    }}
                />
            </IconButton>
            <Typography variant="caption" color="primary" fontSize="small" fontWeight={600}>
                {currentModelSettings?.backgroundColor ?? DEFAULT_MODEL_SETTINGS.backgroundColor}
            </Typography>
        </Stack>
    );
}

function DistanceUnitsInput() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();

    const handleOnChange = async (e: SelectChangeEvent<string>) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, { units: e.target.value });
        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {UNITS_LABEL}
            </Typography>
            <Select
                variant="standard"
                size="small"
                value={currentModelSettings?.units ?? METRES_UNIT_VALUE}
                sx={{ width: '140px' }}
                onChange={handleOnChange}
            >
                {PROJECT_DISTANCE_UNITS.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                        <Typography variant="body2">{unit.label}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </Stack>
    );
}

function XYZAxisToggle() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const currentModelSettings = useAppSelector(selectCurrentModelSettings);
    const currentModel = useAppSelector(selectCurrentModel);
    const isChecked = currentModelSettings?.showXyzAxis;
    const selectedModelIndex = useAppSelector(selectSelectedModelIndex);
    const { syncProject } = useProjectSynchronizer();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!currentModel) {
            return;
        }

        const modifiedSettings = getUpdatedSettings(currentModel, {
            showXyzAxis: event.target.checked,
        });
        dispatch(
            updateModelAtIndexForCurrentProject([
                { settings: modifiedSettings },
                selectedModelIndex,
            ]),
        );
        syncProject();
    };

    const label = isChecked ? ON_LABEL : OFF_LABEL;
    // TODO (GEOM-548): Show/hide XYZ axis based on the toggle
    return (
        <Stack direction="row" className={classes.propertyInput}>
            <Typography width={81} variant="caption" color="secondary">
                {XYZ_AXIS_LABEL}
            </Typography>
            <FormControlLabel
                label={<Typography variant="body2">{label}</Typography>}
                control={<Switch size="small" checked={isChecked} onChange={handleChange} />}
            />
        </Stack>
    );
}
