import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import ZoomIcon from '@local/web-design-system/dist/icons/Camera/ZoomIcon';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import Divider from '@mui/material/Divider/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useEffect } from 'react';

import { GtmMeshDetectorAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { toHighlightViewId } from 'src/hooks/defects/uid';
import { useDefectsVisualizationManager } from 'src/hooks/defects/useDefectsVisualizationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import { useAppSelector } from 'src/store/store';
import { selectedSceneObjectIds } from 'src/store/visualization/selectors';
import { detectorActionToDefectString } from 'src/utils/typeTransformations';

import { useStyles } from './ArtifactListItemControl.styles';

export function ArtifactListItemControl({
    artifactName,
    artifactIndex,
    action,
}: {
    artifactName: string;
    artifactIndex: number;
    action: GtmMeshDetectorAction;
}) {
    const { classes } = useStyles();
    const applyTrace = useTrace('artifact-list-item');
    const selectedObjectId = useAppSelector(selectedSceneObjectIds)[0];
    const defectData = useAppSelector(issueDataForObjectAndAction(selectedObjectId, action));
    const { removeHighlightsForObject, renderHighlights } = useDefectsVisualizationManager();
    const { getZoomToViewTool } = useBaseXyz();

    // TODO: Handle multiple selected objects
    const defectName = detectorActionToDefectString(action);
    const viewId = toHighlightViewId(selectedObjectId, defectName, artifactIndex.toString());
    function handleSearchClick() {
        removeHighlightsForObject(selectedObjectId, action);
        renderHighlights(selectedObjectId, defectData!, action, artifactIndex);
        getZoomToViewTool().zoomToView(viewId);
    }
    useEffect(() => removeHighlightsForObject(selectedObjectId, action), [defectData]);

    return (
        <Grid
            item
            container
            className={classnames(classes.root, { [classes.errorContainer]: false })}
        >
            <Grid
                item
                container
                className={classes.artifactListItemControl}
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
            >
                <Grid item container zeroMinWidth alignItems="center">
                    <Grid
                        item
                        container
                        xs
                        alignItems="stretch"
                        wrap="nowrap"
                        className={classes.nameContainer}
                        automation-id={applyTrace(artifactName)}
                    >
                        <Grid item container direction="row" className={classes.icon}>
                            <ZoomIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    handleSearchClick();
                                }}
                            />
                            <Divider
                                orientation="vertical"
                                flexItem
                                className={classes.actionDivider}
                            />
                            <Typography variant="body2" color="inherit" className={classes.name}>
                                {artifactName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
