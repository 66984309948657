import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass } from '@local/webviz/dist/xyz';

import { Point } from 'src/visualization/Common/CoordinatesInput.types';

export function getPlaneSnapshot(
    label: string,
    origin: Point,
    direction: Point,
    sideLength: number,
): UpdateSnapshot {
    const elementId = `plane-${label}`;
    const viewId = label;

    return {
        [elementId]: {
            id: elementId,
            __class__: ElementClass.Surface,
            vertices: computeVerticalPlaneFromOrigin(origin, direction, sideLength),
            triangles: [0, 1, 2, 0, 2, 3],
        },

        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            color: [240, 240, 240],
            color_back: [240, 240, 240],
            wireframe: false,
            showFaces: true,
            opacity: 1,
        }),
    };
}

/*
Calculate the vertices of a vertical plane with a given `origin` and coplanar to the `direction`
vector. The plane extends `sideLength` units horizontally and vertically, centered at the `origin`.
*/
export function computeVerticalPlaneFromOrigin(
    origin: Point,
    direction: Point,
    sideLength: number,
): Float32Array {
    const directionLength = Math.sqrt(direction.x ** 2 + direction.y ** 2 + direction.z ** 2);
    const halfSideLength = sideLength / 2.0;
    const pointOffset = [
        (direction.x / directionLength) * halfSideLength,
        (direction.y / directionLength) * halfSideLength,
        halfSideLength,
    ];

    // prettier-ignore
    const p0 = [
        origin.x - pointOffset[0],
        origin.y - pointOffset[1],
        origin.z - pointOffset[2],
    ];

    // prettier-ignore
    const p1 = [
        origin.x + pointOffset[0],
        origin.y + pointOffset[1],
        origin.z + pointOffset[2],
    ];

    // prettier-ignore
    return Float32Array.from([
        ...p0,
        p1[0], p1[1], p0[2],
        ...p1,
        p0[0], p0[1], p1[2],
    ]);
}
