import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            width: '275px',
            position: 'absolute',
            right: '16px',
            top: '16px',
            zIndex: 2,
        },
        modelTitle: {
            padding: theme.spacing(2),
        },
        settingsSection: {
            padding: theme.spacing(2),
            gap: theme.spacing(1.5),
        },
        propertyInput: {
            height: '32px',
            alignItems: 'center',
        },
    }) as const;

export const useStyles = makeStyles()(styles);
