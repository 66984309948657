import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';

import { useLazyUploadFile } from 'src/hooks/evoContext/useLazyUploadFile';
import { createFileFromProject } from 'src/hooks/utils';
import { setCurrentProjectVersionId } from 'src/store/project/projectSlice';
import { useAppDispatch, store } from 'src/store/store';
import { ERROR_UPLOADING_PROJECT } from 'src/strings';

export function useProjectSynchronizer() {
    const [UploadFileTrigger] = useLazyUploadFile();
    const dispatch = useAppDispatch();
    const { addMessage } = useMessagesContext();

    return {
        syncProject: async () => {
            // Calling store.getState() (as opposed to pulling the state from a selector) ensures
            // that the latest state is used, even if the state has changed since the
            // function was created (i.e. agnostic of the react render loop).
            const currentProject = store.getState().project.current.project;

            const updatedFile = createFileFromProject(currentProject);

            return UploadFileTrigger(updatedFile)
                .then((response) => {
                    if (!response.data) {
                        throw new Error('No data returned from upload');
                    }
                    dispatch(setCurrentProjectVersionId(response.data.version_id));
                })
                .catch((error) => {
                    addMessage({
                        message: ERROR_UPLOADING_PROJECT,
                        type: NotificationType.ERROR,
                    });
                    trackError(
                        `Error: ${error} uploading new version of project "${updatedFile.name}"`,
                    );
                    return Promise.reject();
                });
        },
    };
}
